import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, selectStyles} from '../component/include'
import Select from 'react-select'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
 const StaffRegistration =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    

const [staff, setStaff] = useState({
	staffName:'',
		fileNo:'',
		 mobile:'',
		  department:[],
		   email:'',
			position:[],
			joinDate:'',
			nin:'',
			gender:{value:'Male', label:'Male'},
			address:'',
			userType:'',
			birthDate:'00-00-0000'	
})
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [errors, setErrors] = useState({});
const [position, setPosition]=useState([])
const [department, setDepartment]=useState([])

const totalStaff  = useSelector(state => state.rootReducer.staff);


const [usergroup, setUsergroup] = useState([])

const fetchDepartment =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1';
	axios.post(url, fd, config)
	.then(result =>setDepartment(result.data))
}

const fetchPosition =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1';
	axios.post(url, fd, config)
	.then(result =>setPosition(result.data))
}

const fetchGroup =()=>{
	var sql ="Select code, status, groupName from tbl_usertype order by groupName ASC" ;
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append('jwt', Token);
	let url = ServerUrl+'/fetchBySql_controller';
	axios.post(url, fd, config).then(result=>{  
		setUsergroup(result.data)
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

const handleSubmit = event =>{
	event.preventDefault();
	 
	let errors = {};   
let email = staff.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
if(!staff.staffName){
	errors.staffName ='Please enter staff name';
}
if(!email){
	errors.email ='Please enter valid email address';
}
if(!staff.mobile){
	errors.mobile ='Please enter staff mobile';
}

if(staff.position.length===0){
	errors.position ='Please select staff position';
}



setErrors(errors);

if(Object.keys(errors).length === 0 ){
    submit()
    }
} 


const getTotal=()=>{
let number = Number(totalStaff.length)+1
	 setStaff({...staff, fileNo:"PAGE/SN/"+('000'+number).slice(-4)});
	
}


useEffect(()=>{
	getTotal()
},[totalStaff]);

const handleBack =()=>{
	setStaff({
		staffName:'',
			fileNo:'',
			 mobile:'',
			  department:[],
			   email:'',
				position:[],
				joinDate:'',
				nin:'',
				gender:{value:'Male', label:'Male'},
				address:'',
				userType:'',
			birthDate:'00-00-0000'		
	})
}

function submit(){  
	let formData = staff; 
	const fd = new FormData();
            fd.append('jwt', Token);
	for(let k in formData){
        fd.append(k,formData[k]);
    }; 
	 fd.append('departments', staff.department.value);
	 fd.append('positions', staff.position.value);
	 fd.append('genders', staff.gender.value);
	 fd.append('usergroup', staff.userType.value);
	 fd.append('code', 'hr'+Math.random().toString(36).substr(2,9));
 setNotice({...notice,  isLoading: true}) 

	let url = ServerUrl+'/save_controller/tbl_staff'
   axios.post(url, fd, config)
   .then(response =>{
	if (response.data.type ==='success'){
		Alerts('Saved!', 'success', response.data.message)
	} else{
		Alerts('Error!', 'danger', JSON.stringify(response.data))
}  
		   
   })
   .catch((error)=>{
	Alerts('Error!', 'danger', JSON.stringify(error.message)) 
})
.finally(()=>{
  setNotice({...notice, isLoading: false}) 
handleBack(); 
})  

}



const ValidateExistence=()=>{
	
	if(staff.email!==''){
		const fd = new FormData();
            fd.append('jwt', Token); 
		fd.append('data', staff.email);
		
		let url = ServerUrl + '/checkExistence/tbl_staff/email'
		axios.post(url, fd, config).then(response=>{
			if(response.data===true){
				setErrors({...errors, email:staff.email+' already choosen'})
				setStaff({...staff, email:''});
			}
	  })
	}
}

const handleChange = event =>{
	const {name, value} = event.target
   setStaff({...staff, [name] : value });
   setErrors({...errors, [name]:''});
}

const handleSelect = (option, action)=>{
	setStaff({...staff, [action.name]: option});
	setErrors({...errors, [action.name]:''})
	 }
	 
	
useEffect(()=>{
	//setIsAuthenticated(verifyRouter(location.pathname))
    fetchDepartment()
	fetchPosition();
	fetchGroup()
 },[]);
        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/staff/view_staff">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
								
								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Staff Information</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
		<div className="card z-depth-0">
		<div className="card-block">
			<div className="row">
									
										<section className="col-md-6">
										
										<div className="form-group">
						<label >Staff Name<span style={{color:'red'}}>*</span></label>
						<input type="text" name="staffName" autoComplete='off' role='presentation' value={staff.staffName} onChange={handleChange} className={errors.staffName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.staffName && errors.staffName}</span>	
							
											</div>
											<div className="form-group">
						<label >File No </label>
						<input type="text"  name="fileNo" value={staff.fileNo} onChange={handleChange} className='form-control' /> 
												
											</div>

										

												<div className="form-group">
						<label > Position<span style={{color:'red'}}>*</span></label> <a href="/staff/settings" className="pull-right">Add More Position</a>
						<Select options={
                            position&&position.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.positionName }
                             })} 
onChange={handleSelect} className={errors.position ? 'form-control form-control-danger' : ''} name="position" value={staff.position}  /> <span style={{color:'red'}}>{errors.position && errors.position}</span>
												
											</div>	
										</section>
										
					<section className="col-md-6">
					<div className="form-group">
						<label >Email Address<span style={{color:'red'}}>*</span></label>
						<input type="text" name="email" autoComplete='off' role={'presentation'} onBlur={ValidateExistence}  value={staff.email} onChange={handleChange} className={errors.email  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.email && errors.email}</span>
						
											</div>
											<div className="form-group">
						<label >Mobile<span style={{color:'red'}}>*</span></label>
						<input type="text" name="mobile" value={staff.mobile} autoComplete='off' role='presentation' onChange={(e)=>setStaff({...staff, mobile:e.target.value.slice(0,11)})} className={errors.mobile  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.mobile && errors.mobile}</span>
												
											</div>
										


											<div className="form-group">
						<label >Date of Employment<span style={{color:'red'}}>*</span></label>
						<input type="date" name="joinDate" value={staff.joinDate} onChange={handleChange} className={errors.joinDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.joinDate && errors.joinDate}</span>
												
											</div>


							</section></div></div></div>


								
			<div className="card z-depth-0">
		<div className="card-block">
						<div className="row">			
										<section className="col-md-6">

										
										
										<div className="form-group">
						<label > Department</label> <a href="/staff/settings" className="pull-right">Add More Department</a>
						<Select options={
                            department&&department.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.departmentName }
                             })} 
onChange={handleSelect} className={errors.department ? 'form-control form-control-danger' : ''} name="department" value={staff.department}  /> <span style={{color:'red'}}>{errors.department && errors.department}</span>
												
											</div>	

											<div className="form-group">
						<label > Current Address</label> 
							<textarea rows='1' name='address' autoComplete='off' role='presentation' className="form-control" value={staff.address} onChange={handleChange}>{staff.address} </textarea>
												
											</div>
											<div className="form-group">
						<label >Date of Birth</label>
						<input type="date" name="birthDate" value={staff.birthDate} onChange={handleChange} className={'form-control'} /> 	
											</div>
										
										</section>
										<section className="col-md-6">
																					
									
										<div className="form-group">
						<label >National Identity Number (NIN)</label>
						<input type="text" name="nin" value={staff.nin} autoComplete='off' role='presentation' onChange={handleChange} className='form-control' />
												
											</div>
											<div className="form-group">
						<label > Gender</label> 
						<Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                ]} 
                        onChange={handleSelect}  name="gender" value={staff.gender} styles={selectStyles}  /> 
											</div>


											<div className="form-group">
						<label > Group</label> 
						
						<Select  options={
				usergroup&&usergroup.map((node, id)=>{
					return {value:node.code, key:id, label:node.groupName}
				})} 
                
onChange={handleSelect}  name="userType"  value={staff.userType} styles={selectStyles} /> 
												
											</div>
										</section>
										</div></div></div>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "> <i className='fa fa-user'></i> Save Staff</button>
	
								</footer>				
							</div>
							</div>
							</div>
							</div>
							
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StaffRegistration) 