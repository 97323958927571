import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import {TextArea,  InputMaskIcon} from '../component/formControl'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import Country from '../component/country'
import TimeZone from '../component/timeZone'
import {ServerUrl, Token, ImagesUrl, config} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {Alerts} from '../component/notify'


const Basic =()=>{
    const [errors, setErrors] = useState([]);    
    const [isSubmitting, setIsSubmitting] = useState(false);
   const [basicDetails, setBasicDetails] = useState([]); 
const [activeStep, setActiveStep] = useState(1);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const steps = [{title: 'Basic Information'}, {title: 'Logo & Social'}, {title: 'Contact Information'}, {title: 'Global Settings'}]
    
    let currency =   [{ value: 'Naira', label: '₦ - Naira' },
        { value: 'Euro', label: '€ - Euro' },
        { value: 'Pound', label: '£ - Pound' },
        { value: 'Dollar', label: '$ - Dollar' },
        { value: 'Yuan', label: '¥ - Yuan' }]

const getCurrency=(value)=>{
    const result = currency.filter(list=>list.value===value)[0];
        return  result.label
}



    const handleOnClickStepper = (step) => {
            setActiveStep(step); 
        }
    
        const handleOnClickNext = useCallback(() => {
            setActiveStep(activeStep + 1);
        }, [activeStep])
        
        const handleOnClickBack = useCallback(() => {
            setActiveStep(activeStep - 1);
        }, [activeStep])


        const [image, setImage] = useState({
            banner:'',
           schoolLogo:'',
           linelogo:''
       })
     

     const fetchBasicDetails = ()=>{  
        const fd = new FormData();
        fd.append("jwt", Token); 
       let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        setNotice({...notice, isDataFetching: true })
        axios.post(url, fd, config)
        .then(result =>{
           var ans = Array.isArray(result.data)
           if(ans){
            setBasicDetails(result.data)
           }else{
            Alerts('Error!', 'danger', JSON.stringify(result.data))
           }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                isDataFetching: false
              }) 
        })     
    }

     useEffect(() => {    
        fetchBasicDetails()
      }, []); 

        const  handleFile = function(fieldName){
            return function(newValue){  
           readURL(newValue);
           }
           
        } 
        const [general, setGeneral] = useState({
            banner:'',
            schoolLogo:'',
            linelogo:''     
        })
        

  const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB

             if (FileSize > 0.9) {
                displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 900 Kb';
               document.getElementById(input.target.name).value = '';
               setImage({...image, [PreviewImage]:''})
               setGeneral({...general, [PreviewImage]:''})
            } else {
                if (input.target.files && input.target.files[0]) { //Check if input has files.
                    setGeneral({...general, [PreviewImage]:input.target.files[0]})
                    var reader = new FileReader();//Initialize FileReader.
                    reader.onload = function (e) {
                        setImage({...image, [PreviewImage]:e.target.result})
                    };
                    reader.readAsDataURL(input.target.files[0]);
                }
            }

        }else{

            displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
            document.getElementById(input.target.name).value = '';
            setImage({...image, [PreviewImage]:''})
            setGeneral({...general, [PreviewImage]:''})
        }   
        
        if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
        }
        
    } 

    const handleSelect = (option, action)=>{
        const std = [...basicDetails]   
            const value =option.value;
		  std[0][action.name] = value;
        setBasicDetails(std);
    };
  
    const handleChange = (e)=>{
        const std =[...basicDetails]   
            const value =e.target.value;
		   std[0][e.target.name] = value;
        setBasicDetails(std);
    }

    const handleSubmit = event =>{
		event.preventDefault();
		setErrors(ValidateInput(basicDetails[0]));
		setIsSubmitting(true);
    }
    
 const ValidateInput=(values)=>{
    let errors = {};     

    if(!values.schoolName){
        errors.schoolName ='Please enter school name';
    }
    
    if(!values.schoolAbbreviation){
        errors.schoolAbbreviation ='Please enter school Abbreviation';
    }
    
    if(!values.admissionNumberPattern){
        errors.admissionNumberPattern ='Please school Admission Pattern';
    }

   return errors; 
   }

   
   const submit=()=>{
    setNotice({...notice, 
        isLoading: true}) 
    let formData = basicDetails[0];
    const fd = new FormData();
    fd.append("jwt", Token); 
    for(let k in formData){
        fd.append(k,formData[k]);
    }; 
   fd.append('logo', general.schoolLogo);
   fd.append('cover', general.banner);
   fd.append('linelogo', general.linelogo);

       let url = ServerUrl+'/update_controller/tbl_basic_details'
       axios.post(url, fd, config)
       .then(response =>{
        if (response.data.type ==='info'){
            Alerts('Info!', 'info', response.data.message)
        }else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }    
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
      })
      .finally(()=>{
        setNotice({...notice, isLoading: false}) 
            setActiveStep(1)
            setIsSubmitting(false);
            setGeneral({ banner:'', schoolLogo:'' })
            setImage({ banner:'', schoolLogo:'' })
            fetchBasicDetails() 
    })
   }
   
useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
    }
    },[errors])


    useEffect(()=>{
        
        //setIsAuthenticated(verifyRouter(location.pathname))
    }, [])
        return (  <>
    
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="School Details">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-info-circle"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">School Details</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

<div className="row">
<div className="col-sm-12">

				{/* <!-- Promotion container start here  --> */}
				
	<div className="card z-depth-0">	
		<div className="card-header">
			<h5>Initial Setup</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">	
        <div className="container">
        {basicDetails.length!==0?    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>:''}
       {basicDetails.length!==0?basicDetails.map((schoolDetails, idx)=> <div key={idx} >
{activeStep === 1 ? 

<div className="row" id="basic">		    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
		<div className="card-block">
			<div className="row">	
			<section className="col-md-6">

            <div className="form-group">
                        <label >School Name <span style={{color:'red'}}>*</span></label>
                     <input type="text" className={errors.schoolName  ? 'form-control form-control-danger' : 'form-control'} name="schoolName" onChange={handleChange} value={schoolDetails.schoolName} /><span style={{color:'red'}}>{errors.schoolName && errors.schoolName}</span>
                                 </div>
       
                        <div className="form-group">
            <label >Software Starting Date </label>
            <input type="date" className='form-control' name="softwareDate" onChange={handleChange} value={schoolDetails.softwareDate} />
                        </div>

                        <div className="form-group">
            <label >Registration No </label>
            <input type="text" className='form-control' name="regNo" onChange={handleChange} value={schoolDetails.regNo} />
                        </div>

         
         
                        <div className="form-group">
            <label >Date of Establishment </label>
            <input type="date" className='form-control' name="establishDate" onChange={handleChange} value={schoolDetails.establishDate} />
                        </div></section>	

	<section className="col-md-6">
    <div className="form-group">
            <label >School Moto </label>
            <input type="text" className='form-control' name="schoolMotto" onChange={handleChange} value={schoolDetails.schoolMotto} />
                        </div>

                      
                        <div className="form-group">
            <label >School Abbreviation <span style={{color:'red'}}>*</span></label>
            <input type="text" className={errors.schoolAbbreviation  ? 'form-control form-control-danger' : 'form-control'} name="schoolAbbreviation" readOnly onChange={handleChange} value={schoolDetails.schoolAbbreviation} /><span style={{color:'red'}}>{errors.schoolAbbreviation && errors.schoolAbbreviation}</span>
                        </div>

                        <div className="form-group">
            <label >Admission Pattern <span style={{color:'red'}}>*</span></label>
            <input type="text" readOnly className={errors.admissionNumberPattern  ? 'form-control form-control-danger' : 'form-control'} name="admissionNumberPattern" placeholder='e.g {schoolAbbreviation}/{AdmissionSession}/{AdmissionNumber}' onChange={handleChange} value={schoolDetails.admissionNumberPattern} /><span style={{color:'red'}}>{errors.admissionNumberPattern && errors.admissionNumberPattern}</span>
                        </div>

                        <div className="form-group">
            <label >Address </label>
            <textarea  type="text" className="form-control " value={schoolDetails.address} name="address" rows="3" onChange={handleChange}  >{schoolDetails.address}</textarea>
                        </div>

									</section>
									</div>
										  
  	</div></div></div>
			

</div>
 :  activeStep === 2 ?  
<div className="row" id="logo">		    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
	<div className="card-block">
			<div className="card z-depth-0">	
			<div className="card-block">
			<div className="row">								
			<section className="col-md-6">
            <div className="form-group">
                                            <label >Upload School Logo</label><br/>
                                        
							<label className="btn btn-inverse" htmlFor="schoolLogo" > <i className="fa fa-upload"></i>Upload School Logo </label>
                    <input id="schoolLogo"  onChange={handleFile('schoolLogo')} className="form-control file" name="schoolLogo" type="file" accept="image/*" /> 
					
                    	<div className="note" style={{color:'red'}}>
			<strong>Size 300 kb,</strong>gif|jpg|png|jpeg Only Allow
		</div>	
										</div>

                                        <div className="form-group">
                                            <label >Upload Abbreviation Logo</label><br/>
                                        
							<label className="btn btn-inverse" htmlFor="linelogo" > <i className="fa fa-upload"></i>Upload Abbrev. Logo </label>
                    <input id="linelogo"  onChange={handleFile('linelogo')} className="form-control file" name="linelogo" type="file" accept="image/*" /> 
					
                    	<div className="note" style={{color:'red'}}>
			<strong>Size 300 kb,</strong>gif|jpg|png|jpeg Only Allow
		</div>	
										</div>


										<div className="form-group">
						<label className="btn btn-inverse" htmlFor="banner" > <i className="fa fa-upload"></i>Upload School Banner </label>
                    <input id="banner"  onChange={handleFile('banner')} className="form-control file" name="banner" type="file" accept="image/*" /> 
						<div className="note" style={{color:'red'}}>
			<strong>Size 500 kb,</strong>gif|jpg|png|jpeg Only Allow</div>	
										</div>
										
									</section>	
									

									
                            <section className="col-md-6">

                            <div className="form-group"> 
                            {schoolDetails.schoolLogo!==''?image.schoolLogo===''?

                     <img id="viewSchoolLogo2" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolDetails.schoolLogo+'?date='+new Date()}`}  style={{display:'block', height:'100px',width:'100px'}} title="School Logo" alt=''  /> :

                <img id="viewSchoolLogo" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={image.schoolLogo}  style={{display:'block',height:'100px',width:'100px'}} title="School Logo" alt='' /> :  

                 <img id="viewSchoolLogo" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={image.schoolLogo}  style={{display:'block',height:'100px',width:'100px'}} title="School Logo" alt='' />        }
                                            </div>

                                          
                                            <div className="form-group"> 
                            {schoolDetails.linelogo!==''?image.linelogo===''?

                     <img id="banner2" className="img-100 img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/about/linelogo.png'}} src={`${ImagesUrl}/about/${schoolDetails.linelogo+'?date='+new Date()}`}   title="School Abbreviation" alt=''  /> :

                <img id="abbreviation" className="image img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/about/linelogo.png'}} src={image.linelogo}   title="School Logo" alt='' /> :  

                 <img id="abbreviation" className="image img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/about/linelogo.png'}} src={image.linelogo}   title="School Abbreviation" alt='' />        }
                                            </div>


                                            <div className="form-group"> 
                            {schoolDetails.banner!==''?image.banner===''?

                     <img id="banner2" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/back.jpg'}} src={`${ImagesUrl}/about/${schoolDetails.banner+'?date='+new Date()}`}  style={{display:'block', height:'200px',width:'300px'}} title="School Banner" alt=''  /> :

                <img id="banner" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/back.jpg'}} src={image.banner}  style={{display:'block',height:'200px',width:'300px'}} title="School Logo" alt='' /> :  

                 <img id="banner" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/back.jpg'}} src={image.banner}  style={{display:'block',height:'200px',width:'300px'}} title="School Cover" alt='' />        }
                                            </div>
                                
									</section>
									</div>
	<div className="row">
	<div className="col-md-12">
    <textarea  type="text" className="form-control " placeholder="Short history about the school" value={schoolDetails.description} name="description" rows="10" onChange={handleChange}  >{schoolDetails.description}</textarea>

				   </div>
		</div>	</div>
			
			</div>		  


		{/* 	<!-- Social here  --> */}

			<div className="card z-depth-0">
	
			<div className="card-block">
				<div className="row">

				<div className="col-sm-12" >
				<h3 >Social Media Handles</h3><hr/><br/>
				</div>
				</div>
			<div className="row">
			<section className="col-md-6">
           
            <div className="form-group">
            <label >Website Address </label>
            <input type="website" className='form-control' name="website" onChange={handleChange} value={schoolDetails.website} />
                        </div>

                        <div className="form-group">
            <label >YouTube Page </label>
            <input type="website" className='form-control' name="youtube" onChange={handleChange} value={schoolDetails.youtube} />
                        </div>

                        <div className="form-group">
            <label >App Link </label>
            <input type="website" className='form-control' name="app" onChange={handleChange} value={schoolDetails.app} />
                        </div>
     
     </section>	
									
									<section className="col-md-6">
                                        
                                    <div className="form-group">
            <label >Facebook Page </label>
            <input type="website" className='form-control' placeholder="https://www.facebook.com" name="facebook" onChange={handleChange} value={schoolDetails.facebook} />
                        </div>

                        <div className="form-group">
            <label >Twitter Handle</label>
            <input type="website" className='form-control' placeholder="https://www.twitter.com" name="twitter" onChange={handleChange} value={schoolDetails.twitter} />
                        </div>

                        <div className="form-group">
            <label >Linkedin Page Id</label>
            <input type="website" className='form-control' placeholder="https://www.twitter.com" name="linkedin" onChange={handleChange} value={schoolDetails.linkedin} />
                        </div>
		
									</section>

									</div>
									</div>
			
			</div>		  
			</div>
			
			</div>		
			</div>
			
</div>
: activeStep === 3 ?  
	<div className="row" id="contact">	    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
		<div className="card-block">
			<div className="row">	
			<section className="col-md-6">

            <div className="form-group">
            <label >City </label>
            <input type="text" className='form-control' name="city" onChange={handleChange} value={schoolDetails.city} />
                        </div>
   

					<div className="form-group">   {/* use country API */}
					<label htmlFor="userName-2" className="block">Country </label>
					
        <Country handleSelect={handleSelect}  country={schoolDetails.country} />
										</div>       

            <div className="form-group">
            <label >State </label>
            <input type="text" className='form-control' name="schoolState" onChange={handleChange} value={schoolDetails.schoolState} />
                        </div>

                        <div className="form-group">
            <label >District </label>
            <input type="text" className='form-control' name="district" onChange={handleChange} value={schoolDetails.district} />
                        </div>
                    
                </section>	

	<section className="col-md-6">
			   

        <div className="form-group">
            <label >Email Address </label>
            <input type="email" className='form-control' name="email" onChange={handleChange} value={schoolDetails.email} />
                        </div>

    <InputMaskIcon mask="999-9999-9999"  name="mobile" icon="fa fa-mobile" label="Mobile"    value={schoolDetails.mobile}  handleChange={handleChange}  />  
   
    <div className="form-group">
            <label >Alternate Mobile </label>
            <input type="text" className='form-control' name="altMobile" onChange={handleChange} value={schoolDetails.altMobile} />
                        </div>

    <TextArea name="googleMap" className="form-control" label="Google Map Link" textValue={schoolDetails.googleMap} handleChange={handleChange} rows="2" />

									</section>
									</div>
								<div className="row>">
								<div className="col-md-12">
					
					<div className="form-group">						
			 <iframe title="School Location" src={schoolDetails.googleMap} width="100%" height="400" frameBorder="0" style={{border:'0'}} allowFullScreen></iframe>
					   </div>
				   </div>
								</div>
									
		  </div></div></div>
			
</div>
: <div className="row" id="global">		    
			<div className="col-md-12">				
			<div className="card z-depth-0">	
		<div className="card-block">
			<div className="row">	
			<section className="col-md-6">
 
			<div className="form-group">
					<label >Display Currency Symbol </label>
                    <Select options={[
                            { value: 'Disable', label: 'Disable' },
                            { value: 'Before', label: 'Before' },
                            { value: 'After', label: 'After' }
                        ]} 
                    onChange={handleSelect} name="currencySymbol" value={{value:schoolDetails.currencySymbol, label:schoolDetails.currencySymbol}}  />	
                </div>

            <div className="form-group">
            <label >Currency Icon </label>
            <Select  options={currency}
   onChange={handleSelect}  name="currencyIcon" value={{value:schoolDetails.currencyIcon, label:getCurrency(schoolDetails.currencyIcon)}}  
            />
            											
        </div>

        <div className="form-group">
        <label >Theme</label>
        <Select  options={[
                    { value: 'Purple', label: 'Purple' },
                    { value: 'Green', label: 'Green' },
                    { value: 'Blue', label: 'Blue' }
                ]} 
   onChange={handleSelect}  name="theme" defaultValue={{value:schoolDetails.theme, label:schoolDetails.theme}}  
            />										
        </div>
        
        <div className="form-group">
           {/*  Get timezone Api */}
<label >Time Zone <font color="red">*</font></label>
       
        <TimeZone handleSelect={handleSelect} data={schoolDetails.timeZone} />             
            </div>   
         
        </section>	

<section className="col-md-6"><br/>


                <div className="form-group">
                    <label >Allow Profile Update By Student</label>
            <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.profileUpdate ==="Yes"} onChange={handleChange} value={"Yes"} name="profileUpdate" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.profileUpdate ==="No"} onChange={handleChange} value={"No"} name="profileUpdate"  />
										<i className="helper"></i>No</label></div>

                    </div>
            
            </div>	


                    <div className="form-group">
                    <label >Play Sound</label>
                   
            <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.playSound ==="Yes"} onChange={handleChange} value={"Yes"} name="playSound" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.playSound ==="No"} onChange={handleChange} value={"No"} name="playSound"  />
										<i className="helper"></i>No</label></div>

                    </div>
            	 </div>					

                <div className="form-group">
                <label >Allow Home Work Edit By Staff</label>
                        <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.homeWorkEdit ==="Yes"} onChange={handleChange} value={"Yes"} name="homeWorkEdit" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.homeWorkEdit ==="No"} onChange={handleChange} value={"No"} name="homeWorkEdit"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    
                    </div>
                    <br/>

                    <div className="form-group">
                    <label >Maintenance Mode</label>
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.maintenance ==="Yes"} onChange={handleChange} value={"Yes"} name="maintenance" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.maintenance ==="No"} onChange={handleChange} value={"No"} name="maintenance"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    </div>
                </section>
                </div>
										  
  	</div></div>
      
      <div className="card z-depth-0">
	
    <div className="card-block">
        <div className="row">

        <div className="col-sm-12" id="staffAttendance" >
        <h3 >Staff Attendance Settings </h3><hr/><br/>
        </div>
        </div>

    <div className="row">
    <section className="col-md-6">
   
    <div className="form-group">
    <label >Time IN </label>
    <input type="time" className='form-control' name="timeIn" onChange={handleChange} value={schoolDetails.timeIn} />
                </div>

                <div className="form-group">
    <label >Time Out </label>
    <input type="time" className='form-control' name="timeOut" onChange={handleChange} value={schoolDetails.timeOut} />
                </div>

                <div className="form-group">
    <label >Schedule Automatic Attendance Mark Absent</label>
    <input type="date" className='form-control'  name="autoAbsent" onChange={handleChange} value={schoolDetails.autoAbsent} />

                </div>

</section>	
                            
                            <section className="col-md-6">
                                
                            <div className="form-group">
    <label >Staff Grace Period In Time In Minutes </label>
    <input type="number" min="0" className='form-control' name="graceTimeIn" onChange={handleChange} value={schoolDetails.graceTimeIn} />
                </div>

                <div className="form-group">
    <label >Staff Grace Period Out Time In Minutes</label>
    <input type="number" min="0" className='form-control' name="graceTimeOut" onChange={handleChange} value={schoolDetails.graceTimeOut} />
                </div>

                <div className="form-group">
                    <label >Attendance Scheduler Status</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.scheduler ==="Yes"} onChange={handleChange} value={"Yes"} name="scheduler" />
										<i className="helper"></i>Active</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={schoolDetails.scheduler ==="No"} onChange={handleChange} value={"No"} name="scheduler"  />
										<i className="helper"></i>Inactive</label></div>

                    </div>
                    
                    </div>
                            </section>

                            </div>
                            </div>
    
<div style={{color:'red'}}>{errors.schoolName && errors.schoolName}</div>
<div style={{color:'red'}}>{errors.schoolAbbreviation && errors.schoolAbbreviation}</div>
<div style={{color:'red'}}>{errors.admissionNumberPattern && errors.admissionNumberPattern}</div>
    </div>
			
			</div></div>}
            </div>):''}
    {basicDetails.length!==0?     
    <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-inverse  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Records</i> : 'Next'}</button>:''}

{activeStep ===1 ? '' : <button className="btn btn-inverse  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
            </div>
</div>
   </div>
                </div>
            </div>
            


                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
       
}

export default React.memo(Basic) 