import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency, config} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, FormatNumber, getClassName } from '../component/globalFunction'
import cellEditFactory from 'react-bootstrap-table2-editor';

import { useDispatch, useSelector } from 'react-redux'

const ClassFees =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
const [feetype, setFeetype] =  useState([]);

    const [studentFee, setStudentFee]=useState({
        studentClass:'',
        selectedClass:[],
        feesDetail: [],
        sessionYear:''
    });


    const [errors, setErrors] = useState({});
   

const [data, setData] =  useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);
  const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

const handleRemoveFee =(ID)=>{
const result = feetype.filter(item=>item.ID!==ID)
setFeetype(result.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
}




  const handleFetchFee =()=>{
	var sql ="Select ID, fees,  price, status, '0' as discountAmount, price as actualFees  from tbl_fee_type where status ='true' order by ID ASC" ;
	const fd = new FormData();
            fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>{
        if(result.data!==0){
        setFeetype(result.data.sort((a, b) =>(a.fees > b.fees) ? 1 : -1))
        }
    })
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

const handleSelect = (option, action)=>{
    var options = option;
    var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
    value.push({classID:options[i].classID, sectionID:options[i].value});
    }

    setStudentFee({...studentFee, [action.name]: option, selectedClass:value });
    setErrors({...errors, [action.name]:''})
};



const handleFetchData =()=>{
    
    setNotice({...notice, isDataFetching:true})
	var sql ="Select ID, classID, sectionID, feesDetail  from tbl_class_fees" ;
	const fd = new FormData();
            fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	axios.post(url, fd, config).then(result=>setData(result.data))
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
        setNotice({...notice, isDataFetching:false})
    })
}
   



     

const ValidateInput =(values)=>{
    let errors = {};    
    if(feetype.length===0){
        errors.studentClass ='There are no fee type listed';
    } 

    if(!Array.isArray(values.studentClass)){
        errors.studentClass ='Please select class';
       // window.scrollTo(0,0);
    }
    
   

 
    return errors;
    }

const handleSubmit =(event)=>{
        event.preventDefault();
        setErrors(ValidateInput(studentFee));
        setIsSubmitting(true);
    }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }else{
            window.scrollTo(0,0);
        }
    },[errors])

   

function submit(){  
    const fd = new FormData();

    //const newList = feetype.filter(item=>Number(item.price)>0)
            fd.append('jwt', Token);
    fd.append('feesList', JSON.stringify(feetype, null, 2));
    fd.append('studentClass', JSON.stringify(studentFee.selectedClass, null, 2));
    
     setNotice({...notice,   isLoading: true}) 

         let url = ServerUrl+'/save_controller/tbl_class_fees'
        axios.post(url, fd, config)
        .then(response =>{

          if (response.data.type ==='success') {
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info') {
                Alerts('Update!', 'default', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }   
                
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false})  
        setIsSubmitting(false)
        setStudentFee({...studentFee, studentClass: [], selectedClass:[] });
        handleFetchData(); 
        handleFetchFee();
        }) 
          
}




const handleDelete = (ID)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+ID)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token);
       fd.append('ID', 'ID')
		fd.append('data', ID)

        let url = ServerUrl+'/delete_controller/tbl_class_fees'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           handleFetchData()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}

   


    const tableHeader = [
        {dataField: 'classID', text: 'Class', formatter: (cell, row) =>getClassName(row.classID, row.sectionID, schoolClass), sort: true, sortValue:(cell, row) =>  schoolClass }, 

        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div> 

        <button type="button" className="btn btn-danger btn-sm " data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
         
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really  sure.?" handleAction={()=>handleDelete(row.ID)} mID={row.ID} />  </div>},
        {dataField: 'feesDetail', text: '', hidden:true}
     ];


     const ClassFeeTable=(props)=>{
        var amount = 0
        const expandRow = {
            
            renderer: row => (
               
                <div>
                    
                  <div className="table-responsive">
            <table className="table ">
                  <thead style={{textAlign:'center'}}>
                      <tr >
                          <th  >Fee Type</th>
                          <th >Amount ({Currency})</th>
                      </tr>
                  </thead>
              <tbody >
             
                     {JSON.parse(row.feesDetail).map((list, idx)=>{
                         
                         amount=Number(list.price)
                   return (
              <tr key={idx}>
                  <td>{list.fees}</td>
                  <td>{FormatNumber(list.price) }</td>
              </tr>)   })}    
              </tbody>
              <tfoot>
                  <tr>
                  <td><b>Amount To Pay </b></td>
                  <td><b>{ FormatNumber(JSON.parse(row.feesDetail).map(list=>list.price)
                  
                  .reduce((acc, item) => Number(acc) + Number(item), 0))}
                 
                 </b> </td>
                 </tr>
              </tfoot>
              </table> 
              </div> 
            </div>),
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandColumnPosition: 'left',
            onlyOneExpanding: true,

            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                  return <i className=" fa fa-minus-square"></i>
                }
                return <i className=" fa fa-plus-square"></i>
              },
              expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                  return <i className=" fa fa-minus-square"></i>
                }
                return <i className=" fa fa-plus-square"></i>
              }
            
          } 
    
          const { SearchBar } = Search;
         
          const customTotal = (from, to, size) => (
             <span >&nbsp;Showing { from } to { to } of { size } items</span>
           );
    
        const options = {
            showTotal: false,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500},
        { text: 'All', value: props.data.length }]
           
          };
    
        return  <ToolkitProvider search className="table-responsive"
                    keyField='ID' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            <div >
                               
                                <BootstrapTable
                                { ...props.baseProps }
                                        hover
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    expandRow={expandRow } 
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }
    
   /*  const handleChangeStatus =(index, e)=>{
        //feetype
        const fees =JSON.parse(JSON.stringify(feetype))
        const indexItem = fees.filter(item=>item.ID===index)[0]
        
        indexItem.status = fees[index].status ==='true'? 'false':'true';
      let newFees = fees.filter(item=>item.status==='true')
      var total = newFees.reduce((initial, item)=>initial+Number(item.price), 0)
    
      setStudentFee({...studentFee, classFee:fees, total:total})
        
    } */



    const tableClassFeesHeader = [
        {dataField: 'fees', text: 'Fee Type', footer:'Total',  editable: false},
        {dataField: 'price', text: 'Amount', footer:columnData => FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0)), formatter: (cell)=>FormatNumber(cell) },
        
        {dataField: 'ID', text: 'Action', footer:'Action', formatter: (cell)=><button type="button" onClick={()=>handleRemoveFee(cell)} className="btn btn-danger btn-sm" title="Delete"><i className="fa fa-trash"></i> </button>, editable: false},        
     ];

    

     const TableClassFees=(props)=>{
        const handleUpdate=(column, newValue, code)=>{ 
             var newFee = feetype.filter(item=>item.ID!==code)

            let edit = feetype.filter(item=>item.ID===code)
                edit[0][column.dataField] = newValue
                setFeetype(edit.concat(newFee).sort((a, b) =>(a.ID > b.ID) ? 1 : -1)) 
            }
            
            
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: false,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='ID' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">
                                
                               
                <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                cellEdit={ cellEditFactory({
                                mode: 'click',
                                blurToSave: true,
                                autoSelectText: true,
                        beforeSaveCell: (oldValue, newValue, row, column) => {
                        if(oldValue !==newValue){
                        handleUpdate(column, newValue, row.ID);
                        
                        return true;
                        }
                        
                    }
                                }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

useEffect(()=>{
    handleFetchFee();
    handleFetchData(); 
},[]);

        return (  <>
   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Class Fees Structure">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a></li>
      <li className="breadcrumb-item"><a href="#!">Class & Section</a> </li>
<li className="breadcrumb-item"><a href="#!">Class Fees Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                
                <div className="row  ">
					<div className="col-sm-6">
	<div className="card z-depth-0">
                <div className="card-header">
                    <h5><i className="fa fa-edit" ></i> Add Fees</h5>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="feather icon-maximizes full-card"></i></li>
                            <li><i className="feather icon-minus minimize-card"></i></li>
                            <li><i className="feather icon-trash-2 close-card"></i></li>
                        </ul>
                    </div>
                </div>
		<div className="card-block">
		<fieldset><div className="row">
		    

            <section className="col-md-12">
            <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select isMulti options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={studentFee.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
            </section>

            </div>
        <div className="row">
    <div className="col-md-12 table-responsive"> 
        
        <TableClassFees data={feetype}  columns={tableClassFeesHeader}  />
         
    </div>
        </div>
          </fieldset>

	<footer className="pull-right">
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-inverse ">Save</button>
	</footer>				
            </div>
            </div>
            </div>

    
    <div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th" ></i>  All Class Fees Structure</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
    <div className="col-md-12 table-responsive">
      
       
    {notice.isDataFetching ? <TableLoader />:
        <ClassFeeTable data={data}  columns={tableHeader} studentFees={feetype} />}

	</div></div>

</div>
</div> </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassFees) 