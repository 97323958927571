import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, schoolName} from '../component/include'
import Select from 'react-select'


const BookBarcode =()=> {
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

   

    const [barcode, setBarcode] = useState({
        bookId:[],
        quantity:'',
        qrcode:''
    })
    
    const [books, setBooks]= useState([])
    
const fetchBooks =()=>{
    const fd = new FormData();
fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_books'
        axios.post(url, fd, config).then(result=>setBooks(result.data))
    }

    const handleChange = event =>{
		const {name, value} = event.target
        setBarcode({...barcode, [name] : value });
       setErrors({...errors, [name]:''});
    }

const fetchBookBarcode =()=>{
           
if(barcode.bookId.length===0){
    setErrors({...errors, bookId:'Please select book'});
}else{

    setNotice({...notice,   isLoading: true}) 

    var title ="Property of "+schoolName+" Book Name: "+barcode.bookId.label
    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append('bookUrl',  title)
    //fd.append('bookUrl',  ServerUrl+'/library_books.php?token='+barcode.bookId.value)
    let url = ServerUrl+'/plugins/qrcode_generator.php?tablename=tbl_books'
   axios.post(url, fd, config)
   .then(response =>{
       setBarcode({...barcode, qrcode:response.data})
                
        })
        .catch((error)=>{
         Alerts('Error!', 'danger', JSON.stringify(error.message)) 
     }).finally(()=>{
        setNotice({...notice,   isLoading: false}) 
     })
    }
 
}

    const handleSelect = (option, action)=>{
        setBarcode({...barcode, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }



useEffect(()=>{
    fetchBooks();
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Print QR Code">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Manage Books</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							

	<div className="col-sm-12">




    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Book</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-12">
                                    
                                        <div className="form-group">
						<label >Select Book <span style={{color:'red'}}>*</span></label>
                        <Select options={
                            books&&books.map((lst, idx)=> {
                               return {key:idx, value: lst.code, label: lst.bookName+' by '+lst.authorName+'', aut:lst.authorName }
                             })} 
                             getOptionLabel={option =>`${option.label} `}
onChange={handleSelect} className={errors.bookId ? 'form-control form-control-danger' : ''} name="bookId" value={barcode.bookId}  /> <span style={{color:'red'}}>{errors.bookId && errors.bookId}</span>	
			</div>
</section>

				</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchBookBarcode} className="btn btn-primary "><i className="fa fa-cogs"></i> Generate QR Code</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	
{barcode.qrcode!==''?
<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> QR Code</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                    </ul>
                </div>
            </div>
            
            <div className="card-block">
        <div className="col-md-12 table-responsive">

      
         {notice.isDataFetching ? <TableLoader />:
         <div className="row">
  <div className="col-md-12">
<div className="well">You can print this QR Code as many as you want by right clicking and save as Image.</div>
</div>
             <div className="card" dangerouslySetInnerHTML={{__html:barcode.qrcode}}></div>
             
            
             
           {/*   <div className="barcodes">
                 barcode here
							<small style={{marginLeft:'18px',fontize: '9px',overflow: 'hidden', display:'inline-block'}}>English comprehension(Wren and martin)</small><br/ >
							<small style={{marginLeft:'18px',fontize: '9px',overflow: 'hidden', display:'inline-block'}}>10</small>
						</div> */}

             </div>
         
        } 

</div>
</div>

    
    </div>
    
    </div>
    </div> :''}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BookBarcode) 