import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, ImagesUrl, Token, Currency, config, schoolName} from '../component/include'
import { longDate, FormatNumber, formatGroupLabel, getClassName } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'

const PaymentReport=()=>{
	
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
const [payment, setPayment] =  useState([]);
const [search, setSearch]=useState({
    fromDate:'',
    toDate:'',
    status:'',
    transactionID:''
})
const schoolInfo = useSelector(state =>state.rootReducer.schoolInfo);

const fetchPayment =()=>{
	setNotice({...notice, isLoading: true}) 
    var sql ="select code, amount, platform, flw_ref, status, transaction_id, tx_ref, customer, remark, dateCreate from tbl_online_payment_report where ID <> '' "
    
    if(search.fromDate.length!==0 && search.toDate.length!==0 ){
        sql = sql + ' and dateCreate between  "'+search.fromDate+'" and "'+search.toDate+'"'
    }

    if(search.transactionID !==''){
        sql = sql + '  and tx_ref = "'+search.transactionID+'"'
    }

    if(search.status.length!==0){
        sql = sql + '  and status =   "'+search.status.value+'"'
    }
    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	if(result.data.length!==0){

setPayment(result.data)
    }else{
        setPayment([])
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})	
})
}


const handleSelect = (option, action)=>{
	setSearch({...search, [action.name]: option});
 }

const handleChange = event =>{
    const {name, value} = event.target
    setSearch({...search, [name] : value });
  
}


const getName=(temp)=>{

    if(temp!=='[]'){
return JSON.parse(temp).name;

}else{
    return '[]'
}}


useEffect(()=>{
    //fetchPayment()
},[]);

   return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Online Payment Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Payment History</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Payment History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">


<section className="col-md-6">
                

                <div className="form-group">
                            <label>From Date</label>
                            <input type='date' name="fromDate" className='form-control' onChange={handleChange} value={search.fromDate} />
            </div>
         </section>

                    <section className="col-md-6">
                                            
        
                <div className="form-group">
                            <label>To Date</label>
                            <input type='date' name="toDate" className='form-control' onChange={handleChange} value={search.toDate} />         </div>
                            </section>

            <section className="col-md-6">
                            

    <div className="form-group">
                <label>Our REF</label>
                <input type='text' name="toDate" className='form-control' onChange={handleChange} value={search.transactionID} />         </div>
                </section>





           <section className="col-md-6">
                    <div className="form-group">
         <label> Transaction Status</label>
            <Select  options={[{value:'successful', label:'successful'},
            {value:'declined', label:'declined'}]
							 
                        } 
onChange={handleSelect} name="status" value={search.status}  /> 

            </div>
                            </section>
									</div>
										
                <div className="row">
                <section className="col-md-12">
<button type="button"  onClick={fetchPayment} className="btn btn-inverse pull-right"><i className="fa fa-search"></i> Search Record</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>


{payment.length!==0?
<div className="card z-depth-0" >
		<div className="card-block">
	
             
    {schoolInfo&&schoolInfo.map((st, id)=>
<div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}, E:{st.email}  </div>
<div style={{textAlign:'center'}}>W: {st.website} Telephone: {st.mobile}, {st.altMobile} </div>

</td>
</tr>
</tbody>
</table>

</div> 
)}  
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Payment Report</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
                
            </tbody>
       </table>
        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>SN</th> 
                <th>Transaction ID</th>
                <th> Invoice No</th>
                <th> Flw Ref</th>
                <th>Amount </th>
                <th>Platform</th>
                <th>Status</th>
                <th> Remark</th>
                <th> Date</th>
                <th> Student</th>
                </tr>
            </thead>
            <tbody >
            
                    {payment&&payment.map((dat, id)=>
                    	<tr key={id}>
                            <td>{id+1}</td>
                        <td>{dat.transaction_id}</td>
                    <td>{dat.tx_ref}</td>
                    <td> <span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{dat.flw_ref}</span></td>
					<td>{FormatNumber(dat.amount)}</td>
					<td>{dat.platform}</td>
					<td>{dat.status==='successful'?<span className="pcoded-badge label label-success">{dat.status}</span>:<span className="pcoded-badge label label-danger">{dat.status}</span>}</td>
					<td>{dat.remark}</td>
					<td>{longDate(dat.dateCreate)}</td>
                    <td><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getName(dat.customer)}</span></td>
						</tr>)}
				</tbody>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/payment_history_online.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
                <input type='hidden' name='jwtToken' value ={Token} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>


        </div></div>

 </div>
        </div>:''}


          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(PaymentReport) 