import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
 const LibrarySettings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

const [purpose, setPurpose]=useState([])
const [division, setDivision]=useState([])
const [binding, setBinding]=useState([])
const [category, setCategory]=useState([])
const [settings, setSettings]=useState([])

const [master, setMaster] = useState({
    purpose:'',
    division:'',
    binding:'',
    category:''
})
const [errors, setErrors] = useState({});

const [activeStep, setActiveStep] = useState(1);

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])

    const steps = [{title: 'Book Purpose'}, {title: 'Division'}, {title: 'Nature of Binding'}, {title:'Category'}, {title: 'Library Settings'}]


    const fetchPurpose =()=>{
        const fd = new FormData();
  fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/purpose/ID/1'
        axios.post(url, fd, config)
        .then(result =>setPurpose(result.data))
    }

    const fetchCategory =()=>{
        const fd = new FormData();
  fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/categories/ID/1'
        axios.post(url, fd, config)
        .then(result =>setCategory(result.data))
    }

    const fetchSettings =()=>{
        const fd = new FormData();
  fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/settings/ID/1'
        axios.post(url, fd, config)
        .then(result =>{
            setSettings(result.data)
            
        })
    }
    const fetchBinding =()=>{
        const fd = new FormData();
  fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/bindingNature/ID/1'
        axios.post(url, fd, config)
        .then(result =>setBinding(result.data))
    }
    const fetchDivision =()=>{
        const fd = new FormData();
  fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/division/ID/1'
        axios.post(url, fd, config)
        .then(result =>setDivision(result.data))
    }
   

   
 const handleSubmit =()=>{
    
        setNotice({...notice,  isLoading: true}) 
    
        const fd = new FormData();
        fd.append('jwt', Token); 
            fd.append('purpose', JSON.stringify(purpose))
            fd.append('division', JSON.stringify(division))
            fd.append('settings', JSON.stringify(settings))
            fd.append('bindingNature', JSON.stringify(binding))
            fd.append('categories', JSON.stringify(category))


             let url = ServerUrl+'/update_controller/tbl_library_settings'
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='info'){
                    Alerts('Saved!', 'info', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
              setNotice({...notice, isLoading: false}) 
              fetchCategory()
                fetchDivision()
                fetchPurpose()
                fetchBinding()
                fetchSettings()
              setActiveStep(1)
            }) 
    }


   
    function submitPurpose(event){ 
        event.preventDefault();
        if(!master.purpose){
            setErrors({...errors, purpose:'This field is Required'})
        }else{
        
            var code = 'p'+Math.random().toString(36).substr(2,9)
            setPurpose(prev=>{
                 return [...prev, {code:code, purposeName:master.purpose}]
            })
            setMaster({...master, purpose:''})
        }
    }

    function submitCategory(event){ 
        event.preventDefault();
        if(!master.category){
            setErrors({...errors, category:'This field is Required'})
        }else{
        
            var code = 'c'+Math.random().toString(36).substr(2,9)
            setCategory(prev=>{
                 return [...prev, {code:code, categoryName:master.category}]
            })
            setMaster({...master, category:''})
        }
    }


    function submitBinding(event){ 
        event.preventDefault();
        if(!master.binding){
            setErrors({...errors, binding:'This field is Required'})
        }else{
        
            var code = 'b'+Math.random().toString(36).substr(2,9)
            setBinding(prev=>{
                 return [...prev, {code:code, bindingName:master.binding}]
            })
            setMaster({...master, binding:''})
        }
    }


    function submitDivision(event){ 
        event.preventDefault();
        if(!master.division){
            setErrors({...errors, division:'This field is Required'})
        }else{
        
            var code = 'b'+Math.random().toString(36).substr(2,9)
            setDivision(prev=>{
                 return [...prev, {code:code, divisionName:master.division}]
            })
            setMaster({...master, division:''})
        }
    }

    const handleChange = event =>{
        const {name, value} = event.target
       setMaster({...master, [name] : value });
       setErrors({...errors, [name]:''})
    }




    const handleDelete = (code, table)=>{ 
        if(table==='purpose'){
            setPurpose(purpose.filter(item=>item.code!==code))
        }else if(table==='division'){
            setDivision(division.filter(item=>item.code!==code))
        }else if(table==='category'){
            setCategory(category.filter(item=>item.code!==code))
        }else if(table==='binding'){
            setBinding(binding.filter(item=>item.code!==code))
        }
    }



    const tablePurposeHeader = [
       /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'purpose')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'purposeName', text: 'Purpose',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          } }     
     ];

     const tableDivisionHeader = [
       /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'division')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'divisionName', text: 'Division',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          } }     
     ];

     const tableCategoryHeader = [
       /*  {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'category')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'categoryName', text: 'Category',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          } }     
     ];

     const tableBindingHeader = [
    /*     {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'binding')}><i className="fa fa-trash"></i></button>, editable: false}, */
        {dataField: 'bindingName', text: 'Binding Nature',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          } }     
     ];




     const BasicTable=(props)=>{
        const handleUpdate=(column, newValue, code)=>{   
            
            var table = props.table
    
            if(table==='purpose'){
                var allData = purpose.filter(item=>item.code!==code)
                let edit = purpose.filter(item=>item.code===code)
                    edit[0].purposeName = newValue
                setPurpose(edit.concat(allData))
    
            }else if(table==='division'){
               const allData = division.filter(item=>item.code!==code)
               let  edit = division.filter(item=>item.code===code)
                    edit[0].divisionName = newValue
                    setDivision(edit.concat(allData))
    
            }else if(table==='category'){
               const allData = category.filter(item=>item.code!==code)
               let  edit = category.filter(item=>item.code===code)
                    edit[0].categoryName = newValue
                    setCategory(edit.concat(allData))
            }else if(table==='binding'){
                const allData = binding.filter(item=>item.code!==code)
                let  edit = binding.filter(item=>item.code===code)
                     edit[0].bindingName = newValue
                     setBinding(edit.concat(allData))
             }
           
         } 
    
    
         const { SearchBar } = Search;
             
              const customTotal = (from, to, size) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
      
         return  <ToolkitProvider search  className="table-responsive"
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               
                                 <SearchBar
                                 { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    



  const days=()=>{
    var value = [];
       var item;
     for(var i =1; i<=60; i++){
        item ={value:i, label:i+' Days'}
        value.push(item);
    }  
return value
  } 
    

    
    
    const handleChangeSettings = (e)=>{
        const std =JSON.parse(JSON.stringify(settings))   
            const value =e.target.value;
           std[0][e.target.name] = value;
        setSettings(std);
    }

    const handleSelect = (option, action)=>{
        const std =JSON.parse(JSON.stringify(settings))   
            const value =option.value;
		  std[0][action.name] = value;
        setSettings(std);
    };

    
  
     useEffect(()=>{
        fetchCategory()
        fetchDivision()
        fetchPurpose()
        fetchBinding()
        fetchSettings()
     },[]);
 
        return (  
   <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Library">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Library Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="purpose">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Enter Book Purpose' name='purpose' onChange={handleChange} value={master.purpose} className={errors.purpose ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitPurpose}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.purpose && errors.purpose}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
       
   <BasicTable data={purpose}  table={'purpose'} columns={tablePurposeHeader}  />
} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  
	 
      :  activeStep === 2 ?  <div className="row setup-content" id="division">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12">

      <div className="form-group">
          <div className="input-group">
                      <input type="text" placeholder='Enter Division Name' name='division' onChange={handleChange} value={master.division} className={errors.division ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                     <button className="btn btn-inverse" type="button" onClick={submitDivision}>Add</button>
             </div>

              <span style={{color:'red'}}>{errors.division && errors.division}</span>
          </div>		
     </section>
         </div>
                              </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {
   <BasicTable data={division}  table={'division'} columns={tableDivisionHeader}  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>:activeStep ===3 ?
<div className="row setup-content" id="binding">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                <input type="text" placeholder='Enter binding Nature' name='binding' onChange={handleChange} value={master.binding} className={errors.binding ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitBinding}>Add</button>
              </div>
               <span style={{color:'red'}}>{errors.binding && errors.binding}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {
   <BasicTable data={binding} table={'binding'} columns={tableBindingHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
: activeStep === 4 ?  <div className="row setup-content" id="category">
		    
<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-12">

<div className="form-group">
    <div className="input-group">
                <input type="text" placeholder='Enter category name' name='category' onChange={handleChange} value={master.category} className={errors.category ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
               <button className="btn btn-inverse" type="button" onClick={submitCategory}>Add</button>
       </div>

        <span style={{color:'red'}}>{errors.category && errors.category}</span>
    </div>		
</section>
   </div>
                        </div>
                        
</div>	
      <div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-12 table-responsive">
{notice.isDataFetching ? <TableLoader />:
   <BasicTable data={category}  table={'category'} columns={tableCategoryHeader}  />} 
   </section>
        </div>
        </div>

</div>	</div>

</div>	
     
</div>

</div>:
<div className="row setup-content" id="settings">
		    
{settings&&settings.map((st, idx)=>
			<div key={idx} className="col-md-12">
					<div className="card z-depth-0">
			<div className="card-block">
			<div className="card z-depth-0">
			<div className="card-block">
		<div className="row">
			<section className="col-md-6">
				<label>Book Issue For Days</label>
					
		
				</section>
				<section className="col-md-6">
				<label>Reissue Allowed</label>
				</section>
</div>

		</div></div>


		<div className="card z-depth-0">
			<div className="card-block">
		<div className="row">
			<section className="col-md-6">
			<div className="form-group">
				<label >For Staff </label>
				<Select   options={days()}  
                    onChange={handleSelect}  name="staffDays"  value={{value: st.staffDays, label: st.staffDays+' Days'}} 
                         className={errors.staffDays ? 'form-control form-control-danger' : ''}    /> <span style={{color:'red'}}>{errors.staffDays && errors.staffDays}</span>
			</div>
				</section>

				<section className="col-md-6">
				<label >Reissue Times</label>
                <div className="form-radio">
               
                <div className="radio radio-inline"><label>
						<input type="radio" checked={st.staffReissue ==="0"} onChange={(e)=>handleChangeSettings(e)} value="0" name="staffReissue" />
										<i className="helper"></i>0 Times</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.staffReissue ==="1"} onChange={(e)=>handleChangeSettings(e)} value="1" name="staffReissue" />
										<i className="helper"></i>1 Times</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.staffReissue ==="2"} onChange={(e)=>handleChangeSettings(e)} value="2" name="staffReissue" />
										<i className="helper"></i>2 Times</label></div>

                         
                    </div>
				</section>
</div>

		</div></div>

        
		<div className="card z-depth-0">
			<div className="card-block">
		<div className="row">
			<section className="col-md-6">
			<div className="form-group">
					<label >For Student </label>
				
					<Select  options={days()}
                        onChange={handleSelect}  name="studentDays"  value={{value: st.studentDays, label: st.studentDays+' Days'}} 
                         className={errors.studentDays ? 'form-control form-control-danger' : ''}    /> <span style={{color:'red'}}>{errors.studentDays && errors.studentDays}</span>
			</div>
					
		
				</section>
				<section className="col-md-6">
				<label >Reissue Times</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={st.studentReissue ==="0"} onChange={(e)=>handleChangeSettings(e)} value="0" name="studentReissue" />
										<i className="helper"></i>0 Times</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.studentReissue ==="1"} onChange={(e)=>handleChangeSettings(e)} value="1" name="studentReissue" />
										<i className="helper"></i>1 Times</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.studentReissue ==="2"} onChange={(e)=>handleChangeSettings(e)} value="2" name="studentReissue" />
										<i className="helper"></i>2 Times</label></div>


								</div>
				</section>
</div>

		</div></div>


        <div className="card z-depth-0">
			<div className="card-block">
		<div className="row">
			<section className="col-md-6">
				<label>Maximum Book Allowed</label>
					
		
				</section>
				<section className="col-md-6">
				<label>Penalty Fee Per Day</label>
				</section>
</div>

		</div></div>



		<div className="card z-depth-0">
			<div className="card-block">
		<div className="row">

        <section className="col-md-6">
				<label >For Staff</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={st.staffMaxBook ==="2"} onChange={(e)=>handleChangeSettings(e)} value="2" name="staffMaxBook" />
										<i className="helper"></i>2 Books</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.staffMaxBook ==="4"} onChange={(e)=>handleChangeSettings(e)} value="4" name="staffMaxBook" />
										<i className="helper"></i>4 Books</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.staffMaxBook ==="6"} onChange={(e)=>handleChangeSettings(e)} value="6" name="staffMaxBook" />
										<i className="helper"></i>6 Books</label></div>


								</div>
				</section>

			<section className="col-md-6">
			<div className="form-group">
					<label >Fine Amount </label>
				
                      <input type="number" placeholder='Enter Fine Amount' min="1" name='staffFine' onChange={(e)=>handleChangeSettings(e)} value={st.staffFine} className='form-control' />
                     
			</div>
					
		
				</section>
				
</div>

		</div></div>

        <div className="card z-depth-0">
			<div className="card-block">
		<div className="row">

        <section className="col-md-6">
				<label >For Student</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={st.studentMaxBook ==="2"} onChange={(e)=>handleChangeSettings(e)} value="2" name="studentMaxBook" />
										<i className="helper"></i>2 Books</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.studentMaxBook ==="4"} onChange={(e)=>handleChangeSettings(e)} value="4" name="studentMaxBook" />
										<i className="helper"></i>4 Books</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.studentMaxBook ==="6"} onChange={(e)=>handleChangeSettings(e)} value="6" name="studentMaxBook" />
										<i className="helper"></i>6 Books</label></div>


								</div>
				</section>

			<section className="col-md-6">
			<div className="form-group">
					<label >Fine Amount </label>
				
                    <input type="number" placeholder='Enter Fine Amount' min="1" name='studentFine' onChange={(e)=>handleChangeSettings(e)} value={st.studentFine} className='form-control' />
                     
			</div>
					
		
				</section>
				
</div>

		</div></div>

		<div className="card z-depth-0">
			<div className="card-block">
		<div className="row">
				<section className="col-md-12">
				<label >Reference Book Allowed To Be Issued</label>
                <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={st.issueReference ==="Enabled"} onChange={(e)=>handleChangeSettings(e)} value="Enabled" name="issueReference" />
										<i className="helper"></i>Enabled</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={st.issueReference ==="Disabled"} onChange={(e)=>handleChangeSettings(e)} value="Disabled" name="issueReference" />
										<i className="helper"></i>Disabled</label></div>

								</div>
				</section>
</div>

		</div></div>



        

		</div>
         
     </div>
	  </div>)}
                </div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(LibrarySettings) 