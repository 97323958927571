import React, {useState, useEffect} from 'react'
import {useParams, useLocation} from 'react-router'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  config, Token, ImagesUrl} from '../component/include'
import {Banner} from '../component/images'


import Error403 from '../settings/error403'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


const GalleryList =()=> {
    let params = useParams();
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [allGallery, setAllGallery]= useState([])

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const fetchGallery =()=>{
        setNotice({...notice,  isDataFetching: true })
        
        const fd = new FormData();
    fd.append('jwt', Token);
          let url = ServerUrl+'/fetchField.php?tablename=tbl_gallery&column=imageList&field=code&data='+params.imageId
    axios.post(url, fd, config).then(result=>{
        console.log(result.data)
              setAllGallery(result.data)
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,  isDataFetching: false })
        })
      }
 
    
   const handleDeleteImage =(row)=>{
                let close =   document.getElementById('btnWarningDialog-'+row.code)
                close.click();

                const fd = new FormData();   
                fd.append('imageUrl', row.imageUrl);
                fd.append('code', params.imageId);
                fd.append('imageList', JSON.stringify(allGallery.filter(rs => rs.code !== row.code), null, 2));
                fd.append('jwt', Token);
                setNotice({...notice, isLoading: true})  
                   let url = ServerUrl+'/update_controller/tbl_delete_gallery_image'
                  axios.post(url, fd, config)
                  .then(response =>{
                    if (response.data.type ==='info'){
                      Alerts('Update!', 'default', response.data.message)
                    } else{
                      Alerts('Error!', 'danger', JSON.stringify(response.data))
                  }       
                  })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', JSON.stringify(error.message)) 
                  }).finally(()=>{
                    setNotice({...notice,  isLoading: false  })   
                    fetchGallery() 
                  }) 
             }   
                    
             

    const handleSubmit = (image, imageUrl, items) =>{
        const fd = new FormData(); 
        fd.append('image', image);   
        fd.append('imageUrl', imageUrl);
        fd.append('code', params.imageId);
        fd.append('imageList', JSON.stringify(allGallery.concat(items), null, 2));

        fd.append('jwt', Token);
        setNotice({...notice, isLoading: true})  
            let url = ServerUrl+'/update_controller/tbl_gallery_image'
            axios.post(url, fd, config)
            .then(response =>{
              if (response.data.type ==='info'){
                Alerts('Update!', 'default', response.data.message)
              } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data.message))
            }       
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            }).finally(()=>{
                setNotice({...notice,  isLoading: false }) 
                fetchGallery()   
            }) 
    } 
    
         
    const tableHeader = [
    
    {dataField: 'imageUrl', text: 'Image', editable: false, formatter:(cell, row)=><img id={cell} className="image" onError={(e)=>{e.target.onerror = null; e.target.src=Banner}} src={`${ImagesUrl}/gallery/${cell}`}  style={thumbnail} title="Image"  alt="Cover" />},


    {dataField: 'order', text: 'Order', sort:true, formatter:(cell, row, index)=><input className="form-control" style={{width:'100px'}} value={row.order} onChange={(e)=>handleChangeOrder(e, index)} name={row.code} type="number" min="1" pattern="\d*"  />},

    {dataField: 'status', text: 'Status', sort:true, editable: false, formatter:(cell, row)=><button onClick={()=>handleStatus(row)}  className={row.status===true?'btn btn-success btn-sm':'btn btn-warning btn-sm'}><i className={row.status===true?'fa fa-check':'fa fa-times'}></i></button>},


      {dataField: 'cover', text: 'Cover', sort:true, editable: false, formatter:(cell, row)=><button onClick={(e)=>handleCover(e, row.code, row.imageUrl)} title="make cover" className={row.cover==='YES'?'btn btn-primary btn-sm':'btn btn-default btn-sm'}>{cell}</button>},

      {dataField: 'code', text: 'Action', formatter:(cell, row)=><div>

      <WarningModal message="This is very dangerous, are you really really sure.?" handleAction={()=>handleDeleteImage(row)} mID={cell} />
      <button className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`}    title="Delete"><i className="fa fa-trash"></i> Delete</button></div>, editable: false}
     ];


     const TableRecord=(props)=>{
      
  
        const handleUpdate=(column, newValue, ID)=>{       
          const fd = new FormData(); 
              fd.append('newValue', newValue);
              fd.append('column', column.dataField);
              fd.append('ID', ID);
              fd.append('jwt', Token);
             let url = ServerUrl+'/updateCustom_controller/tbl_gallery'
            axios.post(url, fd, config)
            //.then(result => console.log(result.data))
            .then()
            .catch((error)=>Alerts('Error!', 'danger', error.message)) 
          }  
        
        
          const { SearchBar } = Search;
              
        const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } entries</span>
          );
          
        
        
            const options = {
                showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '25', value: 25}, {text: '50', value: 50}, {text: '100', value: 100}, { text: 'All', value: props.data.length }]
               
              };
         
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                <div >
                                  <div className="form-group">
                                    <SearchBar
                                    { ...props.searchProps } style={{height:'40px'}} className="form-control form-control-sm" placeholder="Type to search!!!" />
                                    &nbsp;
                                
                             
                                    </div>
                                    
                                  <BootstrapTable
                                   { ...props.baseProps }
                                               striped
                                           hover
                                           condensed
                                           noDataIndication={<div className="alert alert-danger">No Record Found</div>}
                                       pagination={paginationFactory(options) }
                                      // expandRow={expandRow }
                                       cellEdit={ cellEditFactory({
                                         mode: 'dbclick',
                                         blurToSave: true,
                                         autoSelectText: true,
                               beforeSaveCell: (oldValue, newValue, row, column) => {
                                 if(oldValue !==newValue){
                                 handleUpdate(column, newValue, row.ID);
                                 
                                return '';
                               }
                               
                             }
                                       }) }
                                   />
                                </div>
                                )
                            }
         </ToolkitProvider>
         }
  
      
const thumbnail ={
    width: '150px',
    height: '100px',
    padding: '2px',
    border: '2px solid lightgray',
    borderRadius: '3px',
    float: 'left',
    margin: '5px'
}

const uploadAreaH2 ={
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: 'sans-serif',
    lineHeight: '50px',
    color: 'darkslategray'
}

const uploadArea = {
    textAlign: 'center',
    overflow: 'auto',
    cursor:'pointer'
    
    }
  
    
const handleStatus=(row)=>{
    var option =  JSON.parse(JSON.stringify(allGallery));  
     
    var value = [];
    for (var i = 0, l = option.length; i < l; i++) {     
    value.push({code:option[i].code, imageUrl:option[i].imageUrl, order:option[i].order, 
      status:option[i].code===row.code?!row.status:option[i].status, cover:option[i].cover });
    }
  
    setAllGallery(value)
    const fd = new FormData();    
          fd.append('code', params.imageId);
          fd.append('imageUrl', '');
          fd.append('jwt', Token);
          fd.append('imageList', JSON.stringify(value, null, 2));
  Update(fd)
  }
  
  
      const Update=(fd)=>{
            
               let url = ServerUrl+'/update_controller/tbl_gallery_update'
              axios.post(url, fd, config)
              .then(response =>{              
                if (response.data.type ==='info'){
                } else{
                  Alerts('Error!', 'danger', JSON.stringify(response.data))
              }       
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
              }).finally(()=>{
                setNotice({...notice,  isLoading: false })    
                //fetchGallery()
              })
      }
  
  
  
  
  const handleChangeOrder=(event, index)=>{
    const {name, value}=event.target
    var option =  JSON.parse(JSON.stringify(allGallery)); 
    option[index].order = value
  setAllGallery(option)
  
      const fd = new FormData();    
      fd.append('code', params.imageId);
      fd.append('imageUrl', '');
      fd.append('imageList', JSON.stringify(option, null, 2));
      fd.append('jwt', Token);
  Update(fd)
  }
  
  const UpdateCover=(fd)=>{
       
    let url = ServerUrl+'/update_controller.php?tablename=tbl_gallery_cover';
   axios.post(url, fd, config)
   .catch((error)=>{
     Alerts('Error!', 'danger', error.message)
        
   }).finally(()=>{
       fetchGallery();
       setNotice({...notice,  isLoading: false  }) 
   })
}


const handleCover=(e, code, imageUrl)=>{    
    var option = JSON.parse(JSON.stringify(allGallery));   
    var value = [];
    var items;
    for (var i = 0, l = (option || []).length; i < l; i++) {
   items = {code:option[i].code, imageUrl:option[i].imageUrl, order:option[i].order, status:option[i].status, cover:option[i].code===code?'YES':'NO' }
    value.push(items);
    }

    setAllGallery(value)
const fd = new FormData(); 
        fd.append("imageList", JSON.stringify(value, null, 2));
        fd.append("code", params.imageId);
        fd.append("imageUrl", imageUrl);
        fd.append("jwt", Token);
        UpdateCover(fd)
}

  
  
  
  
  
      const openFile=()=>{
          document.getElementById('photo').click();
      }
      const dragOver=(e)=>{        
          e.preventDefault();
          e.stopPropagation();
      }
      const dragEnter=(e)=>{        
          e.preventDefault();
          e.stopPropagation();
      }
  
      const dropFile=(e)=>{        
          e.preventDefault();
          e.stopPropagation();
          var file = e.dataTransfer.files;
         
           handleDropFile(file[0])
              e.dataTransfer.clearData();  
          
      }
  
      const  handleFile = function(fieldName){
        return function(newValue){  
          
       readURL(newValue);
       }
       
    } 


    const readURL = (input)=>{
       
        let displayMessage = '';
          var fileName =  document.getElementById('photo').value;

        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png"  || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 1) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
           document.getElementById(input.target.name).value = '';
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
               
               let Id = Math.random().toString(36).substr(2, 9)
                let imageUrl = Id+'_'+input.target.files[0].name
                let items = {code:Id, imageUrl:imageUrl, order:Number(allGallery.length)+1, status:true, cover:'NO'}
           
                handleSubmit(input.target.files[0], imageUrl, items)
            }
        }
        }else{

            displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1 mb'
           document.getElementById(input.target.name).value = '';
        }   
        
        if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 

  

    const handleDropFile=(file)=>{
   
        let displayMessage = '';
        var fileName =  file.name;
     
      var idxDot = fileName.lastIndexOf(".") + 1;
      var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile==="jpg" || extFile==="jpeg" || extFile==="png"  || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
          //TO DO    
          
           var FileSize = file.size / 1024 / 1024;  // in MB
      if (FileSize > 0.9) {
          displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
      } else {
          if (file) { //Check if input has files.
             
             let code = Math.random().toString(36).substr(2, 9)
              let imageUrl = code+'_'+file.name
              let items = {code:code, imageUrl:imageUrl, order:Number(allGallery.length)+1, status:true, cover:'NO'}
         
              handleSubmit(file, imageUrl, items)
          }
      }
      }else{
     
          displayMessage = 'Only jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1 Mb'
      }   
      
      if(displayMessage.length > 0){
          Alerts('Error!', 'danger', displayMessage)
        }
     
    }


    

    useEffect(()=>{
    /*   setIsAuthenticated(verifyRouter(location.pathname)) */
        fetchGallery()
     },[]);
        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Gallery">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-image"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/cms/gallery">Gallery</a> </li>
                 
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


         
                <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Add Images</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
            
<section  className="col-md-12">

        <div className="" id="UploadPhotos" aria-expanded="true" >
  <div className="well well-sm">
 
  <div className="dropzone"  onClick={openFile} onDragEnter={(e)=>dragEnter(e)} onDrop={(e)=>dropFile(e)} onDragOver={(e)=>dragOver(e)} onDragLeave={(e)=>dragOver(e)} >


<input type="file" style={{display:'none'}} onChange={handleFile('photo')} accept="image/*" name="photo" id="photo" />
 
  <div className="upload-area" style={uploadArea} id="uploadfile">
 <br/> <i className="fa fa-camera fa-3x"></i>
      <h2 id="htext" style={uploadAreaH2}>Drag picture here Or <span style={{color:'blue'}}>Click to choose</span></h2>
  </div>


            </div>
       

  </div>

  

</div>

</section>

							
</div>

</div>  
</div>  </div>
							
           </div>
                            
       </div></div>


                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Album List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allGallery}  columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(GalleryList) 