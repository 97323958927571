import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token, config} from '../component/include'
import {longDate, getDays} from '../component/globalFunction'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'
import { Alerts } from '../component/notify'
const HolidaysList =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [allHolidays, setAllHolidays]= useState([])
    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    const fetchAllHolidays =()=>{
      
      const fd = new FormData();
      fd.append('jwt', Token);
        let sql = "Select * from tbl_events_holidays where eventType='Holidays' and sessionYear = '"+CurrentSession+"'  order by startDate DESC"
     
            let url = ServerUrl+'/fetchBySql_controller'
          
            fd.append("sql", sql)
            
            axios.post(url, fd, config).then(result =>setAllHolidays(result.data)) 
    }

    const tableHeader = [
        {dataField: 'ID', text: 'Title', formatter:(cell)=>'School Holiday', editable:false},
        {dataField: 'title', text: 'Reason', sort:true, editable:false},
        {dataField: 'startDate', text: 'Date',sort:true, editable:false, formatter: (cell, row)=>`${longDate(cell)} `},       
        {dataField: 'endDate', text: 'Day', editable:false, sort:true, formatter: (cell, row)=>getDays(row.startDate)}
     ];


     const HolidayTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
       
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'200px', width:'200px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>

      <div className="dt-buttons btn-group pull-right"> 
      <a href="/timetable/define_holidays" className="btn btn-outline-success waves-effect waves-light" >
            <i className="fa fa-plus"></i>Add New </a>	


    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
       

<form method='POST' action={ServerUrl+'/excel_controller.php?api=sessionholidays'} target='_blank'>
      
<input type='hidden' name='session' value ={CurrentSession} />
      <input type='hidden' name='jwt' value ={Token} />
     
<button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>

</form>

<form method='POST' action={ServerUrl+'/printout/holidays.php?token='+userToken} target='_blank'>
<input type='hidden' name='session' value ={CurrentSession} />
      <input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>

</form>
        </div>
    </div> 
    </div> 
      
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                    
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    




useEffect(()=>{
  //setIsAuthenticated(verifyRouter(location.pathname))
    fetchAllHolidays();
    handleSession()
},[]);




        return (    <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="School Holiday">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Holidays List</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Holidays List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <HolidayTable data={allHolidays}  columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(HolidaysList) 