import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config,  FormatNumber} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getRank, longDate, getCAPoint} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
import { useDispatch, useSelector } from 'react-redux'
const SubjectResultSummary =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [examtype, setExamtype]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allResult, setAllResult]=useState([])
    const [subject, setSubject]= useState([])
    const [result, setResult] = useState({
        studentClass:[],
        subject:[],
        examtype:[]
    })

    const [stat, setStat] = useState({
        average:0,
        below:0,
        above:0,
        total:0
    })

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select exam type';
        } 
        if(values.subject.length===0){
            errors.subject ='Please select subject';
        } 
        return errors;
        }


        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const fetchSubject =(type, section)=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+type+"' and r.sectionID ='"+section+"' group by r.subjectCode  order by r.displayOrder ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            setSubject(result.data)
        }else{  
    setSubject([])
        }
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }

  

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])

const handleSelect = (option, action)=>{
   setErrors({...errors, [action.name]:''})
   if(action.name==='studentClass'){
       
    setResult({...result, [action.name]:option, examtype:[], subject:[]});
    fetchSubject(result.examtype.value, option.value)
   }else if(action.name==='examtype'){
       
    setResult({...result, [action.name]: option });
   fetchSubject(option.value, result.studentClass.value)
   }else{
       
    setResult({...result, [action.name]: option });
   }
   setAllResult([])
  
}


const handleUpdateResult =(resultData, average)=>{

    const fd = new FormData(); 
    fd.append('subjectResult', JSON.stringify(resultData, null, 2));
    fd.append('average', average);
  fd.append('jwt', Token);
       let url = ServerUrl+'/update_controller/tbl_exam_result_average';
      axios.post(url, fd, config)
}

const fetchResult =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT 1+(SELECT count(*) from tbl_exam_result a WHERE a.total > e.total and a.subjectCode = '"+result.subject.value+"'  and a.examCode ='"+result.examtype.value+"' and a.sectionID ='"+result.studentClass.value+"' ) as rank, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, e.examDate, e.maxMark, e.firstCA, e.teacher, e.absent, e.secondCA, e.exam, e.comment, e.total, e.subjectposition, e.grade, e.remark, e.code from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ='Studying' and e.subjectCode = '"+result.subject.value+"' and e.examCode ='"+result.examtype.value+"' and e.sectionID ='"+result.studentClass.value+"'  order by s.studentName ASC"



    


const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
        var resultLength = result.data.filter(item=>Number(item.total)>0)
        var total = result.data.reduce((acumulator, item)=>acumulator + Number(item.total), 0)
       
        var avg = total/resultLength.length
        var above = resultLength.filter(item=>Number(item.total)>=avg)
        var below = resultLength.filter(item=>Number(item.total)< avg)
setStat({
    average:FormatNumber(total/resultLength.length),
    below:below.length,
    above:above.length,
    total:resultLength.length
    
})
handleUpdateResult(result.data, avg);
setAllResult(resultLength)
    }else{

        setStat({
            average:0,
            below:0,
            above:0,
            total:0
            
        })
        setAllResult([])
        Alerts('Info!', 'info', 'There are no result found for the selected subject')
    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}




useEffect(()=>{
    //setIsAuthenticated(verifyRouter(location.pathname))
    fetchSubject()
    fetchExamType()
    
},[]);

        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Subject Marks Analysis">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Exam Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Subject Marks</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                    <section className="col-md-4">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>       

     <section className="col-md-4">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>     	
     <section className="col-md-4">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	



        {allResult.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width">
       <tbody>
       <tr>
       {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )}
                </tr>
            </tbody>
       </table>

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>SUBJECT MARK ANALYSIS </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.studentClass.label}</b></td>
                    <td colSpan={2}>Exam Type: <b> {result.examtype.label} Examination</b></td>
                    <td >Subject: <b> {result.subject.label}</b></td>
                </tr>
                <tr>
                    <td >Total Student: <b>{stat.total}</b></td>
                    <td >Average: <b> {stat.average}</b></td>
               
                    <td >On & Above Average: <b> {stat.above}</b></td>
                    <td>Below Average: <b> {stat.below}</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                    <th>Student Details</th>
                    <th >Gender</th>
                    <th >Score</th>
                    <th>Rank</th>
                    <th>Grade</th>
                    <th>Remark</th>

                </tr>
            </thead>
            <tbody>{allResult&&allResult.map((row, idx)=> 
              row.absent==='No' && row.total >0?  <tr key={idx}>
                    <td>{idx+1}</td>
                <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{row.admissionNumber} - {row.studentName} <br/>

                {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Terminated on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                    <td>{row.gender}</td>
                    <td>{row.total}</td>
                    <td>{row.rank}<sup>{getRank(row.rank)}</sup></td>
                    <td>{row.grade}</td>
                    <td>{row.remark}</td>
                </tr>:[])}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/subject_marks_analysis.php?token='+userToken} target='_blank' className='pull-right' >
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='subject' value ={result.subject.value} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='stat' value ={JSON.stringify(stat, null, 2)} />
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(SubjectResultSummary) 