import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import {ServerUrl, Token, config, Currency} from '../component/include'
import axios from 'axios'
import {  convertDate, FormatNumber, getClassName, getMonth, getMonthByDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
    const [allFamily, setAllFamily]= useState([])  
      

    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
const [classPayment, setClassPayment] = useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const [fees, setFees] = useState([])
const [statistics, setStatistics] = useState({
    family:0,
    paidFees:0,
    feeType:0,
    charge:0
});

const [studentFee, setStudentFee]=useState({
  studentClass:[],
  feecharge:'',
  sumTotal:'',
  sumPaid:'',
  sumBalance:''
});
const [feecharge, setFeecharge] = useState([]);

const fetchFeecharge =()=>{
  const fd = new FormData();
fd.append('jwt', Token);
  let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
  axios.post(url, fd, config).then(result=>setFeecharge(result.data))
}


    const fetchFamily =()=>{
      
        const fd = new FormData();
        var sql =" Select  sum((select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code)) as paid, fm.familyName, sum(s.total) as total from  tbl_student_fees s, tbl_fees_charge f, tbl_family fm, tbl_students st  where f.code = s.feeCharge  and fm.code = st.family_name and st.code = s.studentCode group by fm.code  "
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                
                setAllFamily(result.data)
                
            }) 
    } 

   

      
    const dataBar = {
      labels: allFamily.map(data=>data.familyName),
      datasets: [
        {
          label: 'Dues ',
          data: allFamily.map(data=>data.total),
          backgroundColor: 'rgb(10, 76, 181)',
          
          
        },
        {
          label: 'Paid ',
          data: allFamily.map(data=>data.paid),
          backgroundColor: 'rgb(49, 168, 13)',
        },
        {
          label: 'Balance ',
          data:allFamily.map(data=>Number(data.total)-Number(data.paid)),
          backgroundColor: 'rgb(209, 20, 93)',
        },
      ],
    }
    
    const optionsBar = {
      scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
    }; 
    
    
const handleSelect = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
  fetchClassPayment(option.value)
 }      


     const fetchClassPayment=(value)=>{
var section = getAllSection();
var sql = "SELECT  sf.classID, sf.sectionID, sf.feeCharge, sum(sf.total) as total,  sum((select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =sf.code)) as paid from  tbl_student_fees sf  where  sf.feeCharge =   '"+value+"' GROUP by sf.sectionID "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
           
          var final = [];
          var data = result.data
  for(var k in section){
    for(var i in data){
        if(data[i].sectionID===section[k].sectionID){
          final.push({label:String(getClassName(section[k].classID, section[k].sectionID, schoolClass)),total:data[i].total,  paid:data[i].paid})
      }
    }
      }

            setClassPayment(final)
         })
     } 
    

const getAllSection =()=>{
  let section = []
  var newClass = [...schoolClass];

   for (var i in newClass){
          if(newClass[i].section[0].sname === ''){   
          section.push({classID:newClass[i].ID, sectionID:newClass[i].ID})
          } else{ 
              for(var k in newClass[i].section){
                  section.push({classID:newClass[i].ID, sectionID:newClass[i].section[k].ID})
                  }
      } 
  } 

  return section
}

     const data= {
      labels: classPayment.map((data)=>data.label),
      datasets: [
        {
          label: 'Dues ',
          data:  classPayment.map(data=>data.total),
          backgroundColor: 'rgb(10, 76, 181)',
          
          
        },
        {
          label: 'Paid ',
          data:  classPayment.map(data=>data.paid),
          backgroundColor: 'rgb(49, 168, 13)',
        },
        {
          label: 'Balance ',
          data:classPayment.map(data=>Number(data.total)-Number(data.paid)),
          backgroundColor: 'rgb(209, 20, 93)',
        },
      ],
    }
    
    const options = {
      scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
    }; 


    const fetchFees =()=>{
       
      var sql = "select datePaid as label,  sum(amount) as data from tbl_fees_payment_history where datePaid >=  '"+convertDate(first)+"' and datePaid  <= '"+convertDate(last)+"'  GROUP BY datePaid order by label  "
      
               const fd = new FormData();
               fd.append("sql", sql);
               fd.append('jwt', Token)
               let url = ServerUrl+'/fetchBySql_controller';
               axios.post(url, fd, config).then(result=>{ 
                 
                setFees(result.data)
               })
           } 

         const dataFees = {
            labels: fees.map((data)=>data.label),
            datasets: [
              {
                label: 'Amount '+Currency,
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: fees.map((data)=>data.data)
              }
            ]
          }; 
          
          
         

         var years = new Date().getFullYear()
          const fetchStatistics = async ()=>{
           
            let sqls = "Select  (select count(*) from tbl_family ) as family, (select sum(amount) from tbl_fees_payment_history where date_format(datePaid, '%Y') ='"+years+"' ) as paidFees, (select count(*) from tbl_fees_charge ) as charge, (select count(*) from tbl_fee_type ) as feeType    "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
                
                setStatistics({
                  family:result.data[0].family,
                  paidFees:result.data[0].paidFees,
                  feeType:result.data[0].feeType,
                  charge :result.data[0].charge
                })
             })
         } 
         


            









     

   useEffect(()=>{
     fetchStatistics()

fetchFees()
     fetchFeecharge()
     fetchFamily()
},[]);

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Exam Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


<div className="row">


<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Feetype</p>
                    <h4 className="m-b-0">{statistics.feeType}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-credit-card f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Fees Schedule</p>
                        <h4 className="m-b-0">{statistics.charge}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-money f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Families</p>
                        <h4 className="m-b-0">{statistics.family}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-users f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Paid ({years})</p>
                            <h4 className="m-b-0">{Currency+FormatNumber(statistics.paidFees)}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>






      </div>

    <br/>


    <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <div className="col-sm-6 pull-left ">
             <strong>
     Class Fees Payment </strong>
               </div>
             <div className="col-sm-4 pull-right ">
             <Select  options={feecharge&&feecharge.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                                     })
                                } 
                                getOptionLabel={option =>`${option.session}  ${option.label}`}       
        onChange={handleSelect}  name="feecharge" value={studentFee.feecharge}  />
							</div>
              <Bar
          data={data}
          options={options}
          
        /> 

             </div>
          </div>
        </div></div> 



        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Fees Payment ({getMonth(new Date().getMonth())}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataFees}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
          
        /> 

             </div>
          </div>
        </div></div> 





        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> Family Fees Payment</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataBar}
          options={optionsBar}
        />  
             </div>
          </div>
        </div></div> 



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Chart) 