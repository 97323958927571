import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'

 const Settings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false,
		isDataFetching: false
	});
    const [notification, setNotification] = useState([])
    const [sendMessage, setSendMessage] = useState({
        receiverType:'All',
        studentClass:[],
        studentList:[],
        allStudent:'Yes',
        messageNumber:'Main',
        message:'',
        emailMessage:''
    })
   
    const [students, setStudents] = useState([]);
	const [schoolClass, setSchoolClass]=useState([]); 
    const [studentDetails, setStudentDetails] = useState([]);

const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({});

const [activeStep, setActiveStep] = useState(1);

const [studentMobile, setStudentMobile] = useState([])
const [studentEmail, setStudentEmail] = useState([])

const countTotalNumber=()=>{
    if(sendMessage.receiverType==='All'){
        if(sendMessage.allStudent==='Yes' && sendMessage.messageNumber==='Main'){
             const answer = students.map((c)=>c.mobile);
             const result = answer.filter((list)=>list!=="");             
            setStudentMobile(result)
        }else if(sendMessage.allStudent==='No' && sendMessage.messageNumber==='Main'){
           let value= []
            sendMessage.studentList.map((st)=>{
              return value.push(st.mobile)
            })

           const result = value.filter((list)=>list!=="");
            setStudentMobile(result)
        }else if(sendMessage.allStudent==='Yes' && sendMessage.messageNumber==='All'){
    
            let answer = []
            let result;
            let mobile =[]
            let father =[]
            let mother =[]
            let altMobile =[]

            students.map((st)=>{
                result={mobile:st.mobile, father:st.father_mobile, mother:st.mother_mobile, altMobile:st.altMobile}
              return answer.push(result)
            })
            answer.map((mob)=>{
                mobile.push(mob.mobile)
                father.push(mob.father)
                mother.push(mob.mother)
                altMobile.push(mob.altMobile)
                return ''
            })
            const mob = mobile.filter((list)=>list!=="");
            const fat = father.filter((list)=>list!=="");
            const moth = mother.filter((list)=>list!=="");
            const altMob = altMobile.filter((list)=>list!=="");
          //  mob + fat + moth + altMob
            const final = [...mob, ...fat, ...moth, ...altMob] 
           
            setStudentMobile(final)
        }else if(sendMessage.allStudent==='No' && sendMessage.messageNumber==='All'){
            let answer = []
            let result;
            let mobile =[]
            let father =[]
            let mother =[]
            let altMobile =[]

            sendMessage.studentList.map((st)=>{
                result={mobile:st.mobile, father:st.fatherMobile, mother:st.motherMobile, altMobile:st.altMobile}
              return answer.push(result)
            })
            answer.map((mob)=>{
                mobile.push(mob.mobile)
                father.push(mob.father)
                mother.push(mob.mother)
                altMobile.push(mob.altMobile)
                return ''
            })
            const mob = mobile.filter((list)=>list!=="");
            const fat = father.filter((list)=>list!=="");
            const moth = mother.filter((list)=>list!=="");
            const altMob = altMobile.filter((list)=>list!=="");
          //  mob + fat + moth + altMob
            const final = [...mob, ...fat, ...moth, ...altMob] 
            setStudentMobile(final)
        }
    }else{
        if(sendMessage.allStudent==='Yes' &&  sendMessage.messageNumber==='Main'){
            const answer = studentDetails.map((c)=>c.mobile);
            const result = answer.filter((list)=>list!=="");
            
           setStudentMobile(result)
       }else if(sendMessage.allStudent==='Yes' &&  sendMessage.messageNumber==='All'){

        let answer = []
        let result;
        let mobile =[]
        let father =[]
        let mother =[]
        let altMobile =[]

        studentDetails.map((st)=>{
            result={mobile:st.mobile, father:st.father_mobile, mother:st.mother_mobile, altMobile:st.altMobile}
          return answer.push(result)
        })
        answer.map((mob)=>{
            mobile.push(mob.mobile)
            father.push(mob.father)
            mother.push(mob.mother)
            altMobile.push(mob.altMobile)
            return ''
        })
        const mob = mobile.filter((list)=>list!=="");
        const fat = father.filter((list)=>list!=="");
        const moth = mother.filter((list)=>list!=="");
        const altMob = altMobile.filter((list)=>list!=="");
      //  mob + fat + moth + altMob
        const final = [...mob, ...fat, ...moth, ...altMob] 
        setStudentMobile(final)
       }else if(sendMessage.allStudent==='No' &&  sendMessage.messageNumber==='All'){
        let answer = []
        let result;
        let mobile =[]
        let father =[]
        let mother =[]
        let altMobile =[]

        sendMessage.studentList.map((st)=>{
           result={mobile:st.mobile, father:st.fatherMobile, mother:st.motherMobile, altMobile:st.altMobile}
          return answer.push(result)
        })
        answer.map((mob)=>{
            mobile.push(mob.mobile)
            father.push(mob.father)
            mother.push(mob.mother)
            altMobile.push(mob.altMobile)
            return ''
        })
        const mob = mobile.filter((list)=>list!=="");
        const fat = father.filter((list)=>list!=="");
        const moth = mother.filter((list)=>list!=="");
        const altMob = altMobile.filter((list)=>list!=="");
      //  mob + fat + moth + altMob
        const final = [...mob, ...fat, ...moth, ...altMob] 
        setStudentMobile(final)
       }else if(sendMessage.allStudent==='No' &&  sendMessage.messageNumber==='Main'){
        let value= []
        sendMessage.studentList.map((st)=>{
          return value.push(st.mobile)
        })
        const result = value.filter((list)=>list!=="");
        
        setStudentMobile(result)
       }
    }
 }

 const countTotalEmail=()=>{
    
    if(sendMessage.receiverType==='All'){
        if(sendMessage.allStudent==='Yes' && sendMessage.messageNumber==='Main'){
             const answer = students.map((c)=>c.email);
             const result = answer.filter((list)=>list!=="");
            setStudentEmail(result)
        }else if(sendMessage.allStudent==='No' && sendMessage.messageNumber==='Main'){
           let value= []
            sendMessage.studentList.map((st)=>{
              return value.push(st.email)
            })

           const result = value.filter((list)=>list!=="");
           setStudentEmail(result)
        }else if(sendMessage.allStudent==='Yes' && sendMessage.messageNumber==='All'){
    
            let answer = []
            let result;
            let email =[]
            let father =[]
            let mother =[]

            students.map((st)=>{
                result={email:st.email, father:st.fatherEmail, mother:st.motherEmail}
              return answer.push(result)
            })
            answer.map((mob)=>{
                email.push(mob.email)
                father.push(mob.father)
                mother.push(mob.mother)
                return ''
            })
            const em =email.filter((list)=>list!=="");
            const fat = father.filter((list)=>list!=="");
            const moth = mother.filter((list)=>list!=="");
          //  mob + fat + moth + altMob
            const final = [...em, ...fat, ...moth] 
            setStudentEmail(final)
        }else if(sendMessage.allStudent==='No' && sendMessage.messageNumber==='All'){
            let answer = []
            let result;
            let email =[]
            let father =[]
            let mother =[]

            sendMessage.studentList.map((st)=>{
                result={email:st.email, father:st.fatherEmail, mother:st.motherEmail}
              return answer.push(result)
            })
            answer.map((mob)=>{
                email.push(mob.email)
                father.push(mob.father)
                mother.push(mob.mother)
                return ''
            })
            const em =email.filter((list)=>list!=="");
            const fat = father.filter((list)=>list!=="");
            const moth = mother.filter((list)=>list!=="");
            const final = [...em, ...fat, ...moth] 
            setStudentEmail(final)
        }
    }else{
        if(sendMessage.allStudent==='Yes' &&  sendMessage.messageNumber==='Main'){
            const answer = studentDetails.map((c)=>c.email);
            const result = answer.filter((list)=>list!=="");
            setStudentEmail(result)
       }else if(sendMessage.allStudent==='Yes' &&  sendMessage.messageNumber==='All'){

        let answer = []
        let result;
        let email =[]
        let father =[]
        let mother =[]

        studentDetails.map((st)=>{
            result={email:st.email, father:st.fatherEmail, mother:st.motherEmail}
          return answer.push(result)
        })
        answer.map((mob)=>{
            email.push(mob.email)
                father.push(mob.father)
                mother.push(mob.mother)
            return ''
        })
        const em =email.filter((list)=>list!=="");
        const fat = father.filter((list)=>list!=="");
        const moth = mother.filter((list)=>list!=="");
        const final = [...em, ...fat, ...moth] 
        setStudentEmail(final)
       }else if(sendMessage.allStudent==='No' &&  sendMessage.messageNumber==='All'){
        let answer = []
        let result;
        let email =[]
        let father =[]
        let mother =[]

        sendMessage.studentList.map((st)=>{
            result={email:st.email, father:st.fatherEmail, mother:st.motherEmail}
          return answer.push(result)
        })
        answer.map((mob)=>{
            email.push(mob.email)
                father.push(mob.father)
                mother.push(mob.mother)
            return ''
        })
        const em =email.filter((list)=>list!=="");
        const fat = father.filter((list)=>list!=="");
        const moth = mother.filter((list)=>list!=="");
        const final = [...em, ...fat, ...moth]
        setStudentEmail(final)
       }else if(sendMessage.allStudent==='No' &&  sendMessage.messageNumber==='Main'){
        let value= []
        sendMessage.studentList.map((st)=>{
          return value.push(st.email)
        })
        const result = value.filter((list)=>list!=="");
        setStudentEmail(result)
       }
    }
 }
const handleChange = event =>{
    let value = event.target.value;
    if (event.target.name==='allStudent'){
        value = event.target.value ==='Yes'? 'No':'Yes';        
        getAllStudent(value);
    }

   setSendMessage({...sendMessage, [event.target.name] : value, studentList:'' });
   setErrors({...errors, [event.target.name]:''})
  
}
const handleSelect = (option, action)=>{
    setSendMessage({...sendMessage, [action.name]: option, studentList:''});
    setErrors({...errors, [action.name]:''})    
    getAllStudent(sendMessage.allStudent);     
};

const handleSelectStudent = (option, action)=>{
    setSendMessage({...sendMessage, [action.name]: option});
    setErrors({...errors, [action.name]:''})     
};

const getAllStudent=(value)=>{
    if (value==='No' && sendMessage.receiverType==='All'){
        setStudentDetails(students)
    }else if(value==='No' && sendMessage.receiverType==='byClass'){
        getStudentByClass(sendMessage.studentClass.value);  
    }   
    return  ''
}

const getStudentByClass=(sectionID)=>{
    const result = students&&students.filter((list)=>list.sectionID===sectionID);
    const answer = result.map((c)=>c);
    setStudentDetails(answer)    
    return  ''
}

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const steps = [{title: 'Select Receiver'}, {title: 'Message '}, {title: 'Confirm '}]
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])
    
    const  handleFetchClass =()=>{
        let url = ServerUrl + '/fetchColumnById_controller/tbl_school_class/schoolClass/1/'+Token;
        axios.get(url).then(result=>setSchoolClass(result.data))
        
            }

     const  fetchStudents =()=>{      
                let url = ServerUrl+'/fetch_controller/tbl_students/'+Token;
                axios.get(url).then(result=>setStudents(result.data))  
             }    

             const  fetchNotification =()=>{  
        
                const fd = new FormData();
           let sql = "Select sms, email, mobile from tbl_notification_module where moduleName='Send Message'"
        
               let url = ServerUrl+'/fetchBySql_controller/'+Token;
             
               fd.append("sql", sql)
               const config = {
                   headers: { 'content-type': 'multipart/form-data'}
               }
               axios.post(url, fd, config).then(result =>setNotification(result.data)) 
           
            }

    const handleSubmit=(event)=>{
        event.preventDefault();
		 setErrors(ValidateInput(sendMessage));
        setIsSubmitting(true); 
      
    }

    useEffect(()=>{
        handleFetchClass()
        fetchStudents()
        fetchNotification()
      //  getAllStudent(sendMessage.allStudent);  
    },[]);

 

    const ValidateInput=(values)=>{
        let errors = {};     
        if(values.receiverType==='byClass'){

            if(values.studentClass.length===0){
            errors.studentClass ='Please select student class';
            }
        }
        if(values.allStudent==='No'){

            if(values.studentList.length===0){
            errors.studentList ='Please select student';
            }
        }
        if(String(notification.map((no)=>no.sms))==='Yes'){
            if(!values.message){
                errors.message ='Please enter message';
            }

            }
           
       return errors; 
       }
       
useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
    },[errors])

    const submit=()=>{
        countTotalNumber()
        countTotalEmail()
    }



   
        return (  
   <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Communication">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Send Login Details</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
   <div className="col-sm-12">

<div className="card z-depth-0">
    <div className="card-block">
    <div className="card z-depth-0">
        <div className="card-block">

        <a href="staff"  className="btn btn-success "><i className="fa fa-user"></i> Staff</a>&nbsp;&nbsp;

        <a href="student" className="btn btn-danger "><i className="fa fa-graduation-cap"></i> Student</a>&nbsp;&nbsp;
</div>
</div></div>
</div>

</div>  </div>
<div className="row">
<div className="col-sm-12">
                  <div className="alert alert-inverse border-inverse background-inverse"  ><h5>Your SMS Balance is: 0 </h5> </div></div>
                                      </div>
<div className="row">
<div className="col-sm-12">
                  <div className="alert alert-info border-info background-info"  ><h5>Message Will Be Sent Using SMS, Email </h5> <a href="notification" ><i className="fa faa-flash animated fa-hand-o-right"></i> Click Here To Configure Notification Settings</a></div></div>
                                      </div>
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Send Login Details to Students</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximize full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="selectClass">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
       <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={sendMessage.receiverType==='All'} onChange={handleChange} value="All" name="receiverType" />
										<i className="helper"></i>All</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={sendMessage.receiverType==='byClass'} onChange={handleChange} value="byClass" name="receiverType" />
										<i className="helper"></i>By Class</label></div>

								</div>
           </div>	

           <div style={{display:sendMessage.receiverType==='byClass'?'block':'none'}} className="form-group">
				<label >Select Class</label>
				<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={sendMessage.studentClass}  /> <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
									</div>
      </section>
          </div>
                               </div>
                               
       </div>	

	</div>
       
       </div>	
			
       </div>
    
</div> : activeStep === 2 ?
	  <div className="row setup-content" id="messages">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-3">
          <label>Select Student</label>
</section>
          <section className="col-md-3">
      <div  className="form-group">
                     
<div className="border-checkbox-section">
					
                    <div className="border-checkbox-group border-checkbox-group-primary">
                        <input className="border-checkbox" type="checkbox"  onChange={handleChange} name="allStudent" value={sendMessage.allStudent} checked={sendMessage.allStudent==='Yes'? true:false} id="allStudent" />
                                <label className="border-checkbox-label" htmlFor="allStudent">All</label>
                            </div>
                            
                            </div> 
   </div>
   
   
   
   </section>

   <section style={{display:sendMessage.allStudent==='Yes'?'none':'block'}} className="col-md-6">
      	<div className="form-group">
						<Select isMulti options={
                            studentDetails&&studentDetails.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.admissionNumber, studentName:list.studentName, co:list.fatherName, fatherMobile:list.father_mobile, mobile:list.mobile, motherMobile:list.mother_mobile, altMobile:list.altMobile, email:list.email, fatherEmail:list.fatherEmail, motherEmail:list.motherEmail }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.co}  ${option.fatherMobile}`}
onChange={handleSelectStudent} className={errors.studentList ? 'form-control form-control-danger' : ''} name="studentList" value={sendMessage.studentList}  /> <span style={{color:'red'}}>{errors.studentList && errors.studentList}</span>
											</div>                                  
	
     </section>
         </div>

         
         <div className="row">
                          
            <section className="col-md-3">
                <label>Number</label>
    </section>

    <section className="col-md-9">

<div className="form-group">
<div className="form-radio">
         <div className="radio radio-inline"><label datatoggle="tooltip" dataplacement="top" title="" dataoriginaltitle="Message will be sent to student registered All number (Students, Fathers, Mothers, Alternative Number)">
                 <input type="radio" checked={sendMessage.messageNumber==='All'} onChange={handleChange} value="All"   name="messageNumber" />
                                 <i className="helper"></i>All</label></div>

                                 <div className="radio radio-inline"><label datatoggle="tooltip" dataplacement="left" title="" dataoriginaltitle="Message will be sent to student registered Main Number" >
                 <input type="radio"   checked={sendMessage.messageNumber==='Main'} onChange={handleChange} value="Main" name="messageNumber" />
                                 <i className="helper"></i>Main</label></div>

                         </div>
    </div>	
    </section>

                    </div>
                    <div className="row">
                          
                          <section className="col-md-3">
                              <label>Fixed Content</label>
                  </section>
              
                  <section className="col-md-9">
              
              <div className="form-group">
             <div>UserName:  UserName<br/>
                    Password : Password<br/>
                    Website : https://kayus.com/login
                  </div>
                  </div>	
                  
                  </section>
              
                                  </div>



                    <div className="row">
                          
                          <section className="col-md-3">
                              <label>SMS Content</label>
                  </section>
              
                  <section className="col-md-9">
              
              <div className="form-group">
             <textarea  className={errors.message ? 'form-control form-control-danger' : 'form-control'} rows="6" name="message" value={sendMessage.message} onChange={handleChange}> {sendMessage.message}</textarea><span style={{color:'red'}}>{errors.message && errors.message}</span>
                  <div style={{color:'green'}}>{sendMessage.message.length} Characters,  {(Math.round(Number(sendMessage.message.length)/160))}  Pages, Remaining {(160 -(Number(sendMessage.message.length)%160))}</div>
                  </div>	
                  
                  </section>
              
                                  </div>
                                 
                              </div>
                              
      </div>	
           	</div>
      
      </div>	
           
      </div>
   
</div>:<div className="row setup-content" id="confirm">
		    
            <div className="col-md-12">
      
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="row">
                       <center><h1>Read And Confirm</h1></center>      
            <section className="col-md-12">
            <div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
            <div style={{color:'red'}}>{errors.studentList && errors.studentList}</div>
            <div style={{color:'red'}}>{errors.message && errors.message}</div>
    <div className="well well-sm alert alert-warning border-warning background-warning"  >{sendMessage.message}</div>
            <div className="well well-sm alert alert-info border-info background-info"  >
                <p>Number Of SMS In This Request Parts : 1 * {studentMobile.length} = {studentMobile.length}</p>
                <p>Number Of People To Send Message In Email {studentEmail.length}</p>
            
            </div> 
      </section>
         
               </div>     </div>
                                    
            </div>	
                     </div>
            
            </div>	
                 
            </div>
         
      </div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Settings) 