import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config, Currency, FormatNumber, DatabaseName} from '../component/include'
import Select from 'react-select'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {formatGroupLabel} from '../component/globalFunction'
import { setTimeout } from 'timers'
const BulkRegistration =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const structure = useRef(null)
    const [classFee, setClassFee] =  useState([]);
	const [totalFees, setTotalFees] =  useState(0);
    const [actualFee, setActualFee] = useState([]);
    const menuLink  = useSelector(state => state.rootReducer.menuLink);
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);	
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const totalStudent  = useSelector(state => state.rootReducer.totalStudent);
    const [currentSession, setCurrentSession] = useState('')
    const [studentList, setStudentList] = useState([]);

    const [isDiplaying, setIsDiplaying] = useState(false);
    const [feecharge, setFeecharge] = useState([]);

    const [admission, setAdmission] = useState({
        total:0,
        remark:'',
        feecharge:[],
        studentClass:[]

    });


    const Initials = 
    { 
        code:'',
        gender:'Male',
        studentName:'',
        admissionNumber:'',
        registerNumber:'',
        emailAddress:'',
        mobile:'',
        dateOfBirth:'0000-00-00'	 
    }

    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
           // Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 

   

    const fetchFeecharge =()=>{
        const fd = new FormData();
    fd.append('jwt', Token)
        let url = ServerUrl+'/fetch_controller/tbl_fees_charge';
        axios.post(url, fd, config).then(result=>setFeecharge(result.data))
    }

    const handleSelect = (option, action)=>{
		setAdmission({...admission, [action.name]: option});
		setErrors({...errors, [action.name]:''})
	/* 	if(action.name==='studentClass'){
			handleFetchClassFee(option.value);
		setIsDiplaying(true) 
		structure.current.scrollIntoView({ behavior: "smooth" });
		} */
	}

    const  getTotal= async()=>{

        GenerateRow(Number(totalStudent)+1)
        }





       const  GenerateRow =(total)=>{  
                var value = [];
                for (var i = 0; i <= 9; i++) {
                    value.push({...Initials,  code:Math.random().toString(36).substr(2, 9), registerNumber:String(Number(total)+i).padStart(4, '0')});
                }      
                setStudentList(value)

            } 


       const createNewRow =()=>{
        setStudentList(studentList.concat({...Initials, code:Math.random().toString(36).substr(2, 9), registerNumber:String(Number(totalStudent+studentList.length)+1).padStart(4, '0')}))
        setErrors({...errors, studentList:''})

    }

    const removeRow=(index)=>{
        setStudentList(studentList.filter((_, i) => i !== index)); 
       }





    const handleChangeStudent = (e, index)=>{
        const std =JSON.parse(JSON.stringify(studentList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setStudentList(std);
    }

    

    const handleSubmit = event =>{
		event.preventDefault();
        var errormessage = [];

        if(admission.studentClass.length===0){
            let msg ='Please select  class';
			setErrors({...errors, studentClass:msg})
			errormessage.push(msg);

        }
       /*  if(admission.feecharge.length===0){
            let msg ='Please select  fees';
			setErrors({...errors, feecharge:msg})
			errormessage.push(msg);
        } */
       // window.scrollTo(0,0);


        if (errormessage.length<=0) {
			submit()
		}
	} 
	

    
    const  submit=()=>{  

   var pattern = schoolInfo&&schoolInfo[0].admissionNumberPattern || ''
   let newPattern = pattern&&pattern.replace('{schoolAbbreviation}', schoolInfo&&schoolInfo[0].schoolAbbreviation);
   
   var shortYear = new Date().toLocaleDateString('en', {year: '2-digit'})
	let pat = newPattern&&newPattern.replace('{AdmissionYear}', shortYear);
        var options = studentList;

    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
        if(options[i].studentName!==''){
    value.push({
        
        code:options[i].code, 
        gender:options[i].gender,
        studentName:options[i].studentName,
        registerNumber:options[i].registerNumber,
        admissionNumber:pat.replace('{AdmissionNumber}', String(admission.total+i).padStart(4, '0')),
        emailAddress:options[i].emailAddress,
        mobile:options[i].mobile,
        dateOfBirth:options[i].dateOfBirth
    })
}
}
if(value.length!==0){
                
        setNotice({...notice, isLoading: true})   

        
const fd = new FormData();

fd.append('currentSession', currentSession);
fd.append('sectionID', admission.studentClass.value);
fd.append('classID', admission.studentClass.classID);

fd.append('remark', admission.remark);
fd.append('feecharge', 'admission.feecharge.value');
fd.append('classFee', JSON.stringify(classFee, null, 2));
fd.append('total', totalFees)
fd.append('jwt', Token)    
fd.append('studentList',  JSON.stringify(value, null, 2))

     let url = ServerUrl+'/save_controller/tbl_bulk_registration' 
        axios.post(url, fd, config)
        .then(response =>{
            setNotice({...notice, isLoading: false})
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
                    }else if(response.data.type ==='info'){
                        Alerts('Success', 'success', response.data.message)

                        setTimeout(() => {
                            window.location.reload()
                        }, 3000);
                            }else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                    }   
            })
            .catch((error)=>{
             setErrors({...errors, errorMessage: JSON.stringify(error.message)})
             setTimeout(() => {
                window.location.reload()
            }, 3000);
            })

}else{
    setErrors({...errors, errorMessage: 'Please fill at least one row complete before you can submit'})
    window.scrollTo(0,0);
}
     }    


     useEffect(()=>{   
  
        if(menuLink.length!==0){
          setIsAuthenticated(menuLink.includes(location.pathname))
        }
        
      },[menuLink]);


      useEffect(()=>{
        getTotal()
    },[totalStudent]);

useEffect(()=>{
    fetchFeecharge()
    handleSession()
},[]);



        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Bulk Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

<div  className="row  ">   


<div className="well">You can use this page to register multiple students. The system will automatically generate their admission number. You can enter as many rows as you like by clicking on Add more.</div>

<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Setup Class & Fees</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     

<section className="col-md-12">
<div className="form-group">
				<label > Admission Class <span style={{color:'red'}}>*</span></label>
				<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={admission.studentClass}  /> <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
									</div>	

</section>


				</div>	
             
               
 </div> </div> 
							
</div>

</div>   

{studentList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
            <form method='post' onSubmit={handleSubmit}> 
        <table className="table">
            <thead>
            <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    <th>Register No</th>
                    <th>Student Name</th>
                    <th>Gender</th>
                    <th>Email Address</th>  
                    <th>Date of Birth</th>
                    <th>Telephone</th> 
                    
    <th><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>


                {studentList&&studentList.map((pl, id)=>
            <tr key={id}>
                
                <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                    <td style={{width:'15px'}}><input type="number" pattern="\d*" className="form-control" name='registerNumber' value={pl.registerNumber}  onChange={(e)=>handleChangeStudent(e, id)} /></td>

                    <td><input type="text" className="form-control" required name='studentName' value={pl.studentName}  onChange={(e)=>handleChangeStudent(e, id)} /></td>

                    <td>
                        <select onChange={(e)=>handleChangeStudent(e, id)}  name="gender"  value={pl.gender} className="form-control">

                        <option value="">Select</option>
                        <option  value="Male" >Male</option> 
                        <option  value="Female" >Female</option>
                            </select>
    
                      </td>
                      <td><input type="email" className="form-control" name='emailAddress' autoComplete="false" value={pl.emailAddress}  onChange={(e)=>handleChangeStudent(e, id)} /></td>

                      <td><input type="date" required className="form-control" name='dateOfBirth' value={pl.dateOfBirth}  onChange={(e)=>handleChangeStudent(e, id)} /></td>
                  
                      <td><input type="text" pattern="\d*" max={11} className="form-control" name='mobile' value={pl.mobile}  onChange={(e)=>handleChangeStudent(e, id)} /></td>
                   
                    <td> <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan='8'>

                    
                <div className="pull-right">
	
	<button type="submit"  className="btn btn-success"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
        </table>
        </form>
        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(BulkRegistration) 