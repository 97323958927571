import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token} from '../component/include'
import Select from 'react-select'
 const Settings =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isError : false,
        isSuccess: false, 
        isWarning: false,
        isInfo: false,
        isDataFetching: false,
        msgResponse:''
        
    }); 

    const [notification, setNotification] = useState([])
    const [sendMessage, setSendMessage] = useState({        
        staff:[],
        allStaff:'Yes',
        messageNumber:'Main',
        message:''
    })
   
    const [staff, setStaff] = useState([]);
	
const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({});

const [activeStep, setActiveStep] = useState(1);

const [staffMobile, setStaffMobile] = useState([])
const [staffEmail, setStaffEmail] = useState([])

const countTotalNumber=()=>{
   
        if(sendMessage.allStaff==='Yes' && sendMessage.messageNumber==='Main'){
             const answer = staff.map((c)=>c.mobile);
             const result = answer.filter((list)=>list!=="");             
            setStaffMobile(result)
        }else if(sendMessage.allStaff==='No' && sendMessage.messageNumber==='Main'){
           let value= []
            sendMessage.staff.map((st)=>{
              return value.push(st.mobile)
            })

           const result = value.filter((list)=>list!=="");
            setStaffMobile(result)
        }else if(sendMessage.allStaff==='Yes' && sendMessage.messageNumber==='All'){
    
            let answer = []
            let result;
            let mobile =[]
            let altMobile =[]

            staff.map((st)=>{
                result={mobile:st.mobile, altMobile:st.altMobile}
              return answer.push(result)
            })
            answer.map((mob)=>{
                mobile.push(mob.mobile)
                altMobile.push(mob.altMobile)
                return ''
            })
            const mob = mobile.filter((list)=>list!=="");
            const altMob = altMobile.filter((list)=>list!=="");
            const final = [...mob, ...altMob] 
           
            setStaffMobile(final)
        }else if(sendMessage.allStaff==='No' && sendMessage.messageNumber==='All'){
            let answer = []
            let result;
            let mobile =[]
            let altMobile =[]

            sendMessage.staff.map((st)=>{
                result={mobile:st.label, altMobile:st.altMobile}
              return answer.push(result)
            })
            answer.map((mob)=>{
                mobile.push(mob.mobile)
                altMobile.push(mob.altMobile)
                return ''
            })
            const mob = mobile.filter((list)=>list!=="");
            const altMob = altMobile.filter((list)=>list!=="");
          //  mob + fat + moth + altMob
            const final = [...mob,  ...altMob] 
            setStaffMobile(final)
        }
    
 }

 const countTotalEmail=()=>{
    
        if(sendMessage.allStaff==='Yes'){
             const answer = staff.map((c)=>c.email);
             const result = answer.filter((list)=>list!=="");
            setStaffEmail(result)

        }else if(sendMessage.allStaff==='No'){
           let value= []
            sendMessage.staff.map((st)=>{
              return value.push(st.email)
            })
           const result = value.filter((list)=>list!=="");
           setStaffEmail(result)
        }
    
 }
const handleChange = event =>{
    let value = event.target.value;
    if (event.target.name==='allStaff'){
        value = event.target.value ==='Yes'? 'No':'Yes';        
       
    }

   setSendMessage({...sendMessage, [event.target.name] : value, staff:[] });
   setErrors({...errors, [event.target.name]:''})
  
}
const handleSelect = (option, action)=>{
    setSendMessage({...sendMessage, [action.name]: option});
    setErrors({...errors, [action.name]:''})   
};

const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const steps = [{title: 'Message '}, {title: 'Confirm '}]
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])
    

     const  fetchStaff =()=>{      
         
        const fd = new FormData();

        let sql = "Select s.ID, s.staffName, s.email, s.mobile, s.altMobile, p.position  from tbl_staff s, tbl_staff_position p  where  p.ID = s.position"

        let url = ServerUrl+'/function/fetchBySql_controller/'+Token;
      
        fd.append("sql", sql)
        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }
        axios.post(url, fd, config).then(result =>setStaff(result.data))
            
    }    

             const  fetchNotification =()=>{  
        
                const fd = new FormData();
           let sql = "Select sms, email, mobile from tbl_notification_module where moduleName='Send Message'"
        
               let url = ServerUrl+'/function/fetchBySql_controller/'+Token;
             
               fd.append("sql", sql)
               const config = {
                   headers: { 'content-type': 'multipart/form-data'}
               }
               axios.post(url, fd, config).then(result =>setNotification(result.data)) 
           
            }

    const handleSubmit=(event)=>{
        event.preventDefault();
		 setErrors(ValidateInput(sendMessage));
        setIsSubmitting(true); 
      
    }

    useEffect(()=>{
        fetchStaff()
        fetchNotification()  
    },[]);

 

    const ValidateInput=(values)=>{
        let errors = {};     
       
        if(values.allStaff==='No'){

            if(values.staff.length===0){
            errors.staff ='Please select student';
            }
        }
        if(!values.message){
            errors.message ='Please enter message';
        }
           
       return errors; 
       }
       
useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
    },[errors])

    const submit=()=>{
        countTotalNumber()
        countTotalEmail()
    }


useEffect(()=>{   
    window.scrollTo(0,0);
    if(notice.msgResponse.length >0){
        
        setTimeout(()=>{
           
            setNotice({
                isLoading : false, 
                isError : false,
                isSuccess: false, 
                isWarning: false,
                isDataFetching: false,
                msgResponse:''
            })
        }, 5000)}

    }, [notice.msgResponse]);

   
        return (  
   <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Communication">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Send Login Details</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                <div className="row  ">
   <div className="col-sm-12">

<div className="card z-depth-0">
    <div className="card-block">
    <div className="card z-depth-0">
        <div className="card-block">

        <a href="staff"  className="btn btn-success "><i className="fa fa-user"></i> Staff</a>&nbsp;&nbsp;

        <a href="student" className="btn btn-danger "><i className="fa fa-graduation-cap"></i> Student</a>&nbsp;&nbsp;

</div>
</div></div>
</div>

</div>  </div>
<div className="row">
<div className="col-sm-12">
                  <div className="alert alert-inverse border-inverse background-inverse"  ><h5>Your SMS Balance is: 0 </h5> </div></div>
                                      </div>
<div className="row">
<div className="col-sm-12">
                  <div className="alert alert-info border-info background-info"  ><h5>Message Will Be Sent Using SMS </h5> <a href="notification" ><i className="fa faa-flash animated fa-hand-o-right"></i> Click Here To Configure Notification Settings</a></div></div>
                                      </div>
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Send Send Login Details to Staff</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximize full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	  <div className="row setup-content" id="messages">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-3">
          <label>Select Staff</label>
</section>
          <section className="col-md-3">
      <div  className="form-group">
                     
<div className="border-checkbox-section">
					
                    <div className="border-checkbox-group border-checkbox-group-primary">
                        <input className="border-checkbox" type="checkbox"  onChange={handleChange} name="allStaff" value={sendMessage.allStaff} checked={sendMessage.allStaff==='Yes'? true:false} id="allStaff" />
                                <label className="border-checkbox-label" htmlFor="allStaff">All</label>
                            </div>
                            
                            </div> 
   </div>
   
   
   
   </section>

   <section style={{display:sendMessage.allStaff==='Yes'?'none':'block'}} className="col-md-6">
   <div className="form-group">
						<Select isMulti options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.mobile, staffName:list.staffName, position:list.position, email:list.email, altMobile:list.altMobile}
                             })} 
                        getOptionLabel={option =>`(${option.position}) ${option.staffName}`}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={sendMessage.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
											</div>                                 
	
     </section>
         </div>
         <div className="row">
                          
            <section className="col-md-3">
                <label>Number</label>
    </section>

    <section className="col-md-9">

<div className="form-group">
<div className="form-radio">
         <div className="radio radio-inline"><label datatoggle="tooltip" dataplacement="top" title="" dataoriginaltitle="Message will be sent to student registered All number (Students, Fathers, Mothers, Alternative Number)">
                 <input type="radio" checked={sendMessage.messageNumber==='All'} onChange={handleChange} value="All"   name="messageNumber" />
                                 <i className="helper"></i>All</label></div>

                                 <div className="radio radio-inline"><label datatoggle="tooltip" dataplacement="left" title="" dataoriginaltitle="Message will be sent to student registered Main Number" >
                 <input type="radio"   checked={sendMessage.messageNumber==='Main'} onChange={handleChange} value="Main" name="messageNumber" />
                                 <i className="helper"></i>Main</label></div>

                         </div>
    </div>	
    </section>

                    </div>
                    <div className="row">
                          
                          <section className="col-md-3">
                              <label>Fixed Content</label>
                  </section>
              
                  <section className="col-md-9">
              
              <div className="form-group">
             <div>UserName:  UserName<br/>
                    Password : Password<br/>
                    Website : https://kayus.com/login
                  </div>
                  </div>	
                  
                  </section>
              
                                  </div>
                    <div className="row">
                          
                          <section className="col-md-3">
                              <label>SMS Content</label>
                  </section>
              
                  <section className="col-md-9">
              
              <div className="form-group">
             <textarea  className={errors.message ? 'form-control form-control-danger' : 'form-control'} rows="6" name="message" value={sendMessage.message} onChange={handleChange}> {sendMessage.message}</textarea><span style={{color:'red'}}>{errors.message && errors.message}</span>
                  <div style={{color:'green'}}>{sendMessage.message.length} Characters,  {(Math.round(Number(sendMessage.message.length)/160))}  Pages, Remaining {(160 -(Number(sendMessage.message.length)%160))}</div>
                  </div>	
                  
                  </section>
              
                                  </div>
                                 
                              </div>
                              
      </div>	
           	</div>
      
      </div>	
           
      </div>
   
</div>:<div className="row setup-content" id="confirm">
		    
            <div className="col-md-12">
      
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="card z-depth-0">
      
        <div className="card-block">
            <div className="row">
                       <center><h1>Read And Confirm</h1></center>      
            <section className="col-md-12">
            <div style={{color:'red'}}>{errors.staff && errors.staff}</div>
            <div style={{color:'red'}}>{errors.message && errors.message}</div>
    <div className="well well-sm alert alert-warning border-warning background-warning"  >{sendMessage.message}</div>
            <div className="well well-sm alert alert-info border-info background-info"  >
                <p>Number Of SMS In This Request Parts : 1 * {staffMobile.length} = {staffMobile.length}</p>
                <p>Number Of People To Send Message In Email {staffEmail.length}</p>
            
            </div> 
      </section>
         
               </div>     </div>
                                    
            </div>	
                     </div>
            
            </div>	
                 
            </div>
         
      </div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Settings) 