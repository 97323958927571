import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, selectStyles, frontUrl, siteLink} from '../component/include'

import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useQuill } from "react-quilljs";
import 'quill/dist/quill.bubble.css'
import { useLocation } from 'react-router-dom'

import { verifyRouter } from '../component/authentication'

import Error403 from '../settings/error403'

const CreateNewPost =()=> {

    let myRef= useRef()
      const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
        
    }); 
    let location =  useLocation()
	const [isAuthenticated, setIsAuthenticated] = useState(true);
const staff = useSelector((state) => state.rootReducer.staff);
const [category, setCategory] = useState([])


	  const {quill, quillRef } = useQuill();
	const [photo, setPhoto] = useState("")

	const [post, setPost] = useState({
		title:'',
	  excerpt:'',
	  author:{label:'', value:''},
	  category:{label:'', value:''},
	  slug:'',
	  subtitle:'',
	  quote:'',
	  displayImage:'',
	  allowComment:'open',
	  approveComment:'closed',
	  relatedCategory:'',
	  publish:'Published',
	  post_image:''
	  })
	  
 const [errors, setErrors] = useState({
	title:'',
	fullDescription:'',
	 author:'',
	category:'',
	slug:'',
	displayImage:'',
	errorMessage:'',
	successMessage:''
 })

 const handleReset=()=>{
      
	window.scrollTo({top:0, left:0, behavior:'smooth'})
			
			setPhoto('')
			const inputElement = document.getElementById('photo')
			inputElement.value = ''
			setPost({
						
		title:'',
		excerpt:'',
					
		author:{label:'', value:''},
		category:{label:'', value:''},
		slug:'',
		subtitle:'',
		quote:'',
				
		allowComment:'open',
		approveComment:'closed',
		relatedCategory:'',
		publish:"Published",
			displayImage:'',
			post_image:''
			})

}

const handleChangeArea = (event) =>{	
	let {name, value} = event.target;	
	 setPost({...post, [name] : value });
 }

const handleAllowComment = (event) =>{		

let {name, value} = event.target;
  value=value==="open"?"closed":"open"
	 setPost({...post, [name]: value});
 }

  

const handleSlug = (event) =>{		
	 setPost({...post, slug : post.title.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/,?[ ]/g, '-') });
	  setErrors({...errors, slug:''})
	
 }


 const handleChange = (event) =>{		
	 let {name, value} = event.target;	
	 if(name==='slug'){
		 value = value.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/,?[ ]/g, '-')
	 }
	 setPost({...post, [name] : value });
	setErrors({...errors, [name]:''});
 }

  const handleSelect = (option, action)=>{
   setPost({...post, [action.name]:option});
	 setErrors({...errors, [action.name]:''})
	  }



 const submit =(event)=>{
	 event.preventDefault();
	 var errormessage = [];

	 let errors = {}; 
	 let msg ='This field is required';
	 if(!post.slug){
        errors.slug =msg;
    } 


	if(!post.title){
        errors.title =msg;
    } 

   

 if(post.category.value.length===0){
	errors.category =msg;
	 } 


 if(post.author.value.length===0){
	errors.author =msg;
	 } 


if(!post.displayImage){
 let img ="Please select image"
 errors.displayImage =img;
	 } 

	

let code = 'p'+Math.random().toString(36).slice(2,9);

		setErrors(errors) 
    if(Object.keys(errors).length === 0){

		setNotice({...notice, isLoading:true})
	
	var newSlug = post.slug+code
	
		 const fd = new FormData();
	 Object.entries(post).forEach(([key, value]) => {
			 fd.append(key, value);
		 }); 

	if(photo){
 fd.append('image', photo, post.post_image); 
	}     

	
 fd.append('code', 'p'+Math.random().toString(36).slice(2,9));
fd.append('fullDescription', quill?.root.innerHTML);
  fd.append('post_author', post.author.value);
   fd.append('post_category', post.category.value);
   fd.append('jwt', Token);


let url = ServerUrl+'/save_controller/tbl_new_post';
   axios.post(url, fd, config)
   .then(response =>{
	 if(response.data.type === 'success'){

	    Alerts('Saved!', 'success', response.data.message)
	
	   window.open(frontUrl+"/cms/news/add?link="+siteLink+"/post/"+post.slug,'_self')
			   } else{
	  setErrors({...errors, errorMessage: response.data.message})
				
	 window.scrollTo({top:0, left:0, behavior:'smooth'})
			   }   
		   })
		   .catch((error)=>{
			  setErrors({...errors, errorMessage:error.message})
		   }).finally(()=>{
			 handleReset()
			 setNotice({...notice, isLoading:false}) 
		   })
	 }
 }


 const fetchCategory =()=>{

       
	var sql ="Select code, name, slug from tbl_blog_category order by name ASC";  
  
	 const fd = new FormData();
	 fd.append("sql", sql);
	 fd.append("jwt", Token);
	 let url = ServerUrl+'/fetchBySql_controller.php';
		axios.post(url, fd, config).then(result=>{  
		  
	 setCategory(result.data)
	   })
	   .catch((error)=>{
		   Alerts('Error!', 'danger', error.message)
	   })
 }




 useEffect(()=>{
	
	/* if (typeof window !== undefined) {
	  let   queryString = new URLSearchParams(window.location.search).get("link")

	  setErrors({...errors, successMessage: queryString})

		 } */
	
  }, [])


 const copyToClipboard =()=>{
         
    const el = document.createElement('textarea');
    el.value = errors.successMessage
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}




 const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
    let displayMessage = '';
      var fileName =  document.getElementById('photo').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile ==="jpg" || extFile ==="jpeg" || extFile ==="png" || extFile ==="gif" || extFile ==="JPG" || extFile ==="JPEG" || extFile ==="PNG" || extFile ==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3mb';
       document.getElementById('photo').value = '';
	   setPhoto('');
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
       
           //Check if input has files.
		   let Id = Math.random().toString(36).substr(2, 9)
		   let fileUrl = Id+'.png'

		   setPost({...post, post_image:fileUrl, displayImage:e.target.result})
		setPhoto(input.target.files[0])
			 setErrors({...errors, displayImage:'', errorMessage:''});          
              
          };
          reader.readAsDataURL(input.target.files[0]); 
          
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3mb'
       document.getElementById(input.target.name).value = '';
       setPhoto('');
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 






useEffect(()=>{
  //setIsAuthenticated(verifyRouter(location.pathname))
  fetchCategory()
}, [])

        
        return (  <> 
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="New Post">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-user-plus"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Blog</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Create Post</a> </li>
                    </PageHeader>

{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
          <div className="col-md-9">

		 
 {errors.successMessage? <div class="alert alert-success background-success">
		  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"><i onClick={copyToClipboard} className='fa fa-copy text-white'></i>
                    </button>
					<h4> {errors.successMessage} </h4>
				</div>:''}





				{errors.errorMessage?	<div class="alert alert-danger background-danger">
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">
							<i class="icofont icofont-close-line-circled text-white"></i>
						</button>
						<h4>{errors.errorMessage}</h4>
					</div>:''}





	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add a New Post</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
       
		<div className="card-block">
        <form method='post' >
		<fieldset>
            
            <div className="row">
									
										<section className="col-md-12">
                                    
						<div className="form-group">
									<label >Add Title<span style={{color:'red'}}>*</span></label>

				<input type="text" placeholder='Post title here' name="title"  className={errors.title ? 'form-control form-control-danger' : 'form-control'}  value={post.title} onBlur={handleSlug} onChange={handleChange}  />
            <div className="text-danger">{errors.title}</div>
							
							</div>


			<div className="form-group">
  <input id="photo"  onChange={handleFile('photo')} className="d-none" name="photo" type="file" accept="image/*" />              

  <label  htmlFor="photo" title="Change Cover" className='btn btn-outline-primary'> <i className="fa fa-image"></i>    Add Media </label>

&nbsp;
{post.displayImage?
 <img id="viewPhoto" className="img-fluid" width="50" height="50" 
			 src={post.displayImage} alt="preview" /> :''}

 <div className="text-danger">{errors.displayImage}</div>
          

			</div>


			
			<div className="form-group ">
                        
                       
						<div style={{ width: '100%', height: 300 }}>
												<div ref={quillRef} />
						
								</div>
										
		</div>
  <div className="text-danger">{errors.fullDescription}</div><br/>
						<div style={{ height: 20 }}></div>

		
						<div className="form-group"><br/>
						<label >Excerpt</label>
						<textarea className="form-control" name="excerpt" onChange={handleChangeArea} rows={3} value={post.excerpt}>{post.excerpt}</textarea>

				<small>Excerpts are optional hand-crafted summaries of your content that can be used in your website</small>
			</div>



			<div className="form-group">
						<label >Author<span style={{color:'red'}}>*</span></label>
				
			<Select options={
                             staff&&staff.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.staffName}
                              })} 
                          
onChange={handleSelect}  name="author" className={errors.author? 'form-control form-control-danger' : ''}   value={post.author}  styles={selectStyles}  /> 


<div className="text-danger">{errors.author}</div>


			</div>


			<div className="form-group">
						<label >Slug <span style={{color:'red'}}>*</span></label>
						<input type="text" name="slug"  className={errors.slug ? 'form-control form-control-danger' : 'form-control'}  value={post.slug}  onChange={handleChange} />
           <div className="text-danger">{errors.slug}</div>

				<small>The “slug” is the URL-friendly version of the name. If empty, the system will generate one automatically.</small>
			</div>



			<div className="form-group">
						<label >News Category <span style={{color:'red'}}>*</span></label>
				
<Select options={
                             category&&category.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.name}
                              })} 
                          
onChange={handleSelect}  name="category" className={errors.category? 'form-control form-control-danger' : ''}   value={post.category}  styles={selectStyles}  /> 

                   <div className="text-danger">{errors.category}</div>

				<small> Category this post belong to.</small>
			</div>



			<div className="form-group">
						<label >Subtitle </label>

						<textarea className="form-control" name="subtitle" onChange={handleChangeArea} rows={3} value={post.subtitle}>{post.subtitle}</textarea>

				<br/>
				<small> This text will appear under the title (optional)</small>
			</div>

			<div className="form-group">
						<label >Quote </label>
				<input type="text" name="quote"  className='form-control' value={post.quote}  onChange={handleChange} />
         

				<small> Show a quote (only when this article shows up in blocks that support quote and only on blocks that are on one column).</small>
			</div>

			</section>
			</div>	</fieldset>
				
                                </form>	</div>
                            
							</div>
							</div>
		
	
	

	<div className="col-md-3">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Discussion</h5>
			
		</div>
		<div className="card-block">


		<div className="col-md-12">
      
<div className="border-checkbox-section">
		
		<div className="border-checkbox-group border-checkbox-group-success">
					<input className="border-checkbox" type="checkbox" id="checkbox1" value={post.allowComment} onChange={handleAllowComment} name="allowComment" checked={post.allowComment==="open"?true:false} />
					<label className="border-checkbox-label" htmlFor="checkbox1"> Allow comments</label>
				</div>

				<div className="border-checkbox-group border-checkbox-group-success">
					<input className="border-checkbox" type="checkbox" id="checkbox2" value={post.approveComment} name="approveComment" onChange={handleAllowComment} checked={post.approveComment==="open"?true:false} />
					<label className="border-checkbox-label" htmlFor="checkbox2"> Approve comment</label>
				</div>
				</div>

	</div>
    </div></div>


	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Publish</h5>
			
		</div>
		<div className="card-block">


		<div className="col-md-12">
      

	  <button type="button" onClick={submit}  className='btn btn-success'><i className="fa fa-share"></i> Publish</button>
	</div>
    </div></div>



</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(CreateNewPost) 