import React, {useState} from 'react'

const scrollTop =()=>{
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}
const Footer = ()=>{
    
    const [displayScroll, setDisplayScroll] = useState(false)
    window.addEventListener('scroll', function(event){
        if(this.scrollY>300){
            setDisplayScroll(true)
        }else{
            setDisplayScroll(false)
        }
    })
    return  <footer  className="background-inverse no-print">
    {displayScroll? <a onClick={scrollTop} href="#!" className="back-to-top">
          <i className="fa fa-angle-double-up"></i>
        </a>:''}
        <div className="pull-right hidden-xs">
        <a  href="#!">Version <strong>1.0.00</strong></a>
        </div>
    
    
        Copyright © 2018 - {new Date().getFullYear()} <b> <a  href="https://kayus.org" target='_blank' className="text-primary">KAYUS</a> </b> by<b> <a  href="https://admotron.com" className="text-danger">Admotron Solutions</a></b>. All rights reserved.
    </footer>
}
export default React.memo(Footer)