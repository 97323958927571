import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import { useLocation } from 'react-router-dom'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'
import { convertDate, getMonth, longDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import { useSelector } from 'react-redux'
import Error403 from '../settings/error403'

const Chart =()=> {
  let location =  useLocation()
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [CurrentSession, setCurrentSession] = useState('')
    const [allSession, setAllSession]= useState([])    

    const menuLink  = useSelector(state => state.rootReducer.menuLink);
    const [isAuthenticated, setIsAuthenticated] = useState(true);

const [application, setApplication] = useState([])
var today = new Date().toISOString().slice(0,10);
var date = new Date()
var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
var cName = "Admission Statistics -"+CurrentSession

const [statistics, setStatistics] = useState({
    request:0,
    submitted:0,
    admitted:0,
    inProgress:0,
   pending:0
});



    const fetchSession =()=>{
      
        const fd = new FormData();
        var sql = "select schoolSession as label, count(*) as data, sum(case when submitted='true'  then 1 else 0 end) AS submitted, sum(case when submitted='true' and admissionStatus='Admitted'  then 1 else 0 end) AS admitted from tbl_admission_request  GROUP BY schoolSession order by label "
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                
                setAllSession(result.data)
                
            }) 
    } 

   

      
        


     const fetchRegistration =()=>{
       
var sql = "select dateCreate as label,  count(*) as data, sum(case when submitted='true'  then 1 else 0 end) AS submitted from tbl_admission_request where dateCreate >=  '"+convertDate(first)+"' and dateCreate  <= '"+convertDate(last)+"'  GROUP BY dateCreate order by label  "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
           
            setApplication(result.data)
         })
     } 
    

          const data = {
            labels: application.map((data)=>data.label),
            datasets: [
              {
                label: 'Total Application',
                data: application.map((data)=>data.data),
                fill: false,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                yAxisID: 'y-axis-1',
              },
              {
                label: 'Total Submitted',
                data: application.map((data)=>data.submitted),
                fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgb(255, 99, 132)',
                yAxisID: 'y-axis-2',
              },
            ],
          }; 
          
    
          const options = {
            scales: {
              yAxes: [
                {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                },
                {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  id: 'y-axis-2',
                  gridLines: {
                    drawOnArea: false,
                  },
                },
              ],
            },
          } 
          
         
        
          const fetchStatistics = async ()=>{
      
            var sql ="Select  startYear from tbl_school_session where status ='Active'"
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetchBySql_controller';
            const {data} = await axios.post(url, fd, config)
            setCurrentSession(data[0].startYear)


            let sqls = "Select  (select count(*) from tbl_admission_request where schoolSession = '"+data[0].startYear+"' ) as request, (select count(*) from tbl_admission_request where submitted='true' and  schoolSession = '"+data[0].startYear+"' ) as submitted, (select count(*) from tbl_admission_request where submitted='false' and  schoolSession = '"+data[0].startYear+"' ) as inProgress, (select count(*) from tbl_admission_request where submitted='true' and admissionStatus='pending' and  schoolSession = '"+data[0].startYear+"' ) as pending,  (select count(*) from tbl_admission_request where submitted='true' and admissionStatus='Admitted' and  schoolSession = '"+data[0].startYear+"' ) as Admitted "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
              
                setStatistics({
                    request:result.data[0].request,
                    submitted:result.data[0].submitted,
                    inProgress:result.data[0].inProgress,
                    pending :result.data[0].pending,
                    admitted:result.data[0].Admitted
                })
             })
         } 
         


           const  handleSession =()=>{

            var sql ="Select  startYear from tbl_school_session where status ='Active'"
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append("jwt", Token);
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config)
            .then(result => {
                if(result.data.length!==0){
                setCurrentSession(result.data[0].startYear)
            }
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            } 

            
            const dataBar = {
                labels: allSession.map((data)=>data.label),
                datasets: [
                  {
                    label: '# of Application',
                    data: allSession.map((data)=>data.data),
                    backgroundColor: 'rgb(209, 20, 93)',
                  },
                  {
                    label: '# of Submitted',
                    data: allSession.map((data)=>data.submitted),
                    backgroundColor: 'rgb(10, 76, 181)',
                  },
                  {
                    label: '# of Admitted',
                    data:allSession.map((data)=>data.admitted),
                    backgroundColor: 'rgb(49, 168, 13)',
                  },
                ],
              }
              
              const optionsBar = {
                scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
              }; 
              

              useEffect(()=>{   
  
                if(menuLink.length!==0){
                  setIsAuthenticated(menuLink.includes(location.pathname))
                }
                
              },[menuLink]);

   useEffect(()=>{
     
     fetchRegistration()
     handleSession()
     fetchStatistics()
     fetchSession()
},[]);

        return (  
   <>
   <div  className="main-body">
   {isAuthenticated?   <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title={cName}>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }



<div className="row">




<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Applications</p>
                    <h4 className="m-b-0">{statistics.request}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-graduation-cap f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                                <p className="m-b-5">Submitted : <b>{statistics.submitted}</b></p>
                                <p className="m-b-5">In Progress : <b>{statistics.inProgress}</b></p>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-users f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Admitted</p>
                        <h4 className="m-b-0">{statistics.admitted}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-users f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Total Pending</p>
                            <h4 className="m-b-0">{statistics.pending}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-users f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>






      </div>

    <br/>

<div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Monthly Admission Request ({getMonth(new Date().getMonth())}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={data}
          options={options}
          
        /> 

             </div>
          </div>
        </div></div> 


        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> Application History</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataBar}
          options={optionsBar}
        />  
             </div>
          </div>
        </div></div> 



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Chart) 