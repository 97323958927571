import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect } from '../component/loader'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config} from '../component/include'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { sort} from '../component/globalFunction'
 const Sitemap =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
    }); 
    const [menu, setMenu]=useState([])
   
    const menuLink  = useSelector(state => state.rootReducer.menuLink);

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
     const fetchNavigation =()=>{
		 
        var sql ="Select menu from tbl_navigation " ;
		const fd = new FormData();
    fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{ 
            setMenu(JSON.parse(result.data.map((data)=>data.menu)).sort(sort))
        })
	}	 

  useEffect(()=>{   
  
    if(menuLink.length!==0){
      setIsAuthenticated(menuLink.includes(location.pathname))
    }
    
  },[menuLink]);
   useEffect(()=>{
     
    fetchNavigation()
},[]);
        return (<>
    
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Sitemap"
	   				menu="Sitemap"
					   />


{/* The Page Body start here */}
<div className="page-body">
						
			
	<div className="row  ">
	<div className="col-sm-12">
								
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-info"></i>  Sitemap</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

                                        					
                    <div className="row ">
        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
          <div className="card shadow-primary">
            <div className="card-body p-4">
 
                 <div className="form-row">
                {menu && menu.map((data, index) =>data.order!=='0'?
                     <div key={index} className="form-group col-sm-4"> 
         <strong><i className={data.menuIcon}></i> {data.menuUrl.length===0?<span className="text-blue">{data.description}</span>:<a  href={data.menuUrl} > <span className="text-blue"> {data.description}</span> </a>}</strong><br/>
                            {data.submenu.length!==0?
                            data.submenu.map((smenu, i)=><>&nbsp;&nbsp;&nbsp;&nbsp;
               
               
                <a key={i} href={smenu.subsubmenu.length===0?smenu.subUrl:'#!'} ><i className={smenu.subsubmenu.length===0?"fa fa-circle-o":'fa fa-circle'} > </i> <span> {smenu.subDescription?smenu.subDescription:smenu.subName}</span> </a><br/>
                
                {smenu.subsubmenu.length!==0?smenu.subsubmenu && smenu.subsubmenu.map((subsub, k)=><>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a key={k} href={subsub.subsubUrl} ><i className="fa fa-circle-o" ></i> {subsub.subsubDescription?subsub.subsubDescription:subsub.subsubName}</a><br/></>):''}
                </>):''}
                
                        </div>:''
                )}
                 </div>

            </div>
          </div>
        </div>
      </div>  
      </div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>

		

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(Sitemap) 