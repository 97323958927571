import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import {ServerUrl, Token, config, Currency} from '../component/include'
import axios from 'axios'
import {  convertDate, formatGroupLabel, FormatNumber, getAllSection, getClassField, getClassName, getMonth, getMonthByDate} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})


const [schoolClassList, setSchoolClassList]=useState({
  
    nursery:0,
    primary:0,
    secondary:0,
    all:0
  
});
const [studentClass, setStudentClass]=useState({
  nursery_present:0,
  nursery_absent:0,
  nursery_m_present:0,
  nursery_f_present:0,
  nursery_m_absent:0,
  nursery_f_absent:0,




  primary_present:0,
  primary_absent:0,
  primary_f_present:0,
  primary_f_absent:0,
  primary_m_present:0,
  primary_m_absent:0,



  secondary_present:0,
 secondary_absent:0,
 secondary_m_present:0,
 secondary_f_absent:0,
 secondary_f_present:0,
 secondary_m_absent:0,
});

    const [studentAttendance, setStudentAttendance] = useState([]);
    const [classAttendance, setClassAttendance] = useState([]);
    const [staffAttendance, setStaffAttendance] = useState([]);
    const [staffMonth, setStaffMonth] = useState([]);
    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

    var firstDayOfYear = new Date(date.getFullYear(), 0, 1, 23, 59, 59)
    var lastDayOfYear = new Date(date.getFullYear()+1, 0, 0, 23, 59, 59)

const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

const [search, setSearch]=useState({
  studentClass:[],
  
});

var today = new Date().toISOString().slice(0,10);
let thisMonth = getMonth(new Date().getMonth());
const [statistics, setStatistics] = useState({});
     

const fetchStaffMonthlyAttendance=()=>{
       
  var sql = "select attendanceDate as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'half' from tbl_staff_attendance where attendanceDate  >=  '"+convertDate(firstDayOfYear)+"' and attendanceDate  <= '"+convertDate(lastDayOfYear)+"' group by  DATE_FORMAT(attendanceDate, '%m.%Y')   order by label  "
  
           const fd = new FormData();
           fd.append("sql", sql);
           fd.append('jwt', Token)
           let url = ServerUrl+'/fetchBySql_controller';
           axios.post(url, fd, config).then(result=>{ 

            let responseData = []
            for (var i = 0; i< result.data.length;  i++) {
              responseData.push(
                  {  label:String(getMonthByDate(result.data[i].label)),
                      present:result.data[i].present,
                      absent:result.data[i].absent,
                      half:result.data[i].half
                  });
                  } 

           setStaffMonth(responseData)
           })
       } 



         const fetchStudentAttendance=()=>{
       
          var sql = "select attendanceDate as label, sum(case when indicator = 'P'   then 1 else 0 end) AS 'present', sum(case when indicator= 'A'   then 1 else 0 end) AS 'absent' from tbl_student_attendance where attendanceType ='fullday' and  attendanceDate >=  '"+convertDate(first)+"' and attendanceDate  <= '"+convertDate(last)+"' group by attendanceDate  order by label  "
          
                   const fd = new FormData();
                   fd.append("sql", sql);
                   fd.append('jwt', Token)
                   let url = ServerUrl+'/fetchBySql_controller';
                   axios.post(url, fd, config).then(result=>{ 
                   setStudentAttendance(result.data)
                   })
               } 



         const fetchStaffAttendance =()=>{
       
          var sql = "select attendanceDate as label, sum(case when attendance = 'P'   then 1 else 0 end) AS 'present', sum(case when attendance = 'A'   then 1 else 0 end) AS 'absent', sum(case when attendance = 'H'   then 1 else 0 end) AS 'half' from tbl_staff_attendance where attendanceDate >=  '"+convertDate(first)+"' and attendanceDate  <= '"+convertDate(last)+"' group by attendanceDate  order by label  "
          
                   const fd = new FormData();
                   fd.append("sql", sql);
                   fd.append('jwt', Token)
                   let url = ServerUrl+'/fetchBySql_controller';
                   axios.post(url, fd, config).then(result=>{ 
                   setStaffAttendance(result.data)
                   })
               } 
    
               const dataStudentAttendance = {
                labels: studentAttendance.map((data)=>data.label),
                datasets: [
                 
                  {
                    label: 'Total Present',
                    data: studentAttendance.map((data)=>data.present),
                    fill: false,
                    backgroundColor: 'rgb(12, 206, 138)',
                    borderColor: 'rgb( 11, 218, 147)',
                    yAxisID: 'y-axis-2',
                  },
                  {
                    label: 'Total Absent',
                    data: studentAttendance.map((data)=>data.absent),
                    fill: false,
                    backgroundColor: 'rgb(255, 99, 132)',
                    borderColor: 'rgb( 173, 24, 39)',
                    yAxisID: 'y-axis-2',
                  }
                ],
              }; 


              
              const optionsStudentAttendance = {
                scales: {
                  yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left',
                      id: 'y-axis-1',
                    },
                    {
                      type: 'linear',
                      display: true,
                      position: 'right',
                      id: 'y-axis-2',
                      gridLines: {
                        drawOnArea: false,
                      },
                    },
                  ],
                },
              } 
              


         const dataStaffAttendance = {
          labels: staffAttendance.map((data)=>data.label),
          datasets: [
           
            {
              label: 'Total Present',
              data: staffAttendance.map((data)=>data.present),
              fill: false,
              backgroundColor: 'rgb(12, 206, 138)',
              borderColor: 'rgb( 11, 218, 147)',
              yAxisID: 'y-axis-2',
            },
            {
              label: 'Total Absent',
              data: staffAttendance.map((data)=>data.absent),
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgb( 173, 24, 39)',
              yAxisID: 'y-axis-2',
            },
            {
              label: 'Total Half Day',
              data: staffAttendance.map((data)=>data.half),
              fill: false,
              backgroundColor: 'rgb(1, 190, 193)',
              borderColor: 'rgb(0, 191, 195)',
              yAxisID: 'y-axis-2',
            }
          ],
        }; 
        
  
        const optionsStaffAttendance = {
          scales: {
            yAxes: [
              {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
              },
              {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                  drawOnArea: false,
                },
              },
            ],
          },
        } 
        
        const data= {
          labels: classAttendance.map((data)=>data.label),
          datasets: [
            {
              label: 'Present ',
              data:  classAttendance.map(data=>data.present),
              backgroundColor: 'rgb(49, 168, 13)',
              
              
            },
            {
              label: 'Absent ',
              data:  classAttendance.map(data=>data.absent),
              backgroundColor: 'rgb( 173, 24, 39)',
            }
          ],
        }
        
        const options = {
          scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
        }; 

        const dataStark = {
          labels: staffMonth.map((data)=>data.label),
          datasets: [
            {
              label: 'Present',
              data: staffMonth.map((data)=>data.present),
              backgroundColor: 'rgb(49, 168, 13)',
            },
            
            {
              label: 'Absent',
              data: staffMonth.map((data)=>data.absent),
              backgroundColor: 'rgb( 173, 24, 39)',
            },
            {
              label: 'Half Day',
              data: staffMonth.map((data)=>data.half),
              backgroundColor: 'rgb(1, 190, 193)',
            }
          ],
        };
        
        
        const optionsStark = {
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
              },
            ],
          },
        };
        


const handleSelect = (option, action)=>{
	setSearch({...search, [action.name]: option});
  fetchClassAttendance(option.value)
 }  

        const fetchClassAttendance =(section)=>{
          var date = new Date()
          const day = 1000 * 60 * 60 * 24
          var answer = []
          for(var i =0; i<7; i++){
              
              answer.push(date.toISOString().slice(0,10))
              date.setTime(date.getTime()-day)
          }
  
      
  var sql = "select attendanceDate as label, sum(case when attendanceType ='fullday' and indicator ='P'   then 1 else 0 end) AS 'present', sum(case when attendanceType ='fullday' and indicator ='A'   then 1 else 0 end) AS 'absent' from tbl_student_attendance where  sectionID = '"+section+"' and attendanceDate IN("+answer.map(data=>'"'+data+'"')+")  GROUP BY attendanceDate order by label  "
  
           const fd = new FormData();
           fd.append("sql", sql);
           fd.append('jwt', Token)
           let url = ServerUrl+'/fetchBySql_controller';
           axios.post(url, fd, config).then(result=>{ 
              
               setClassAttendance(result.data)
           })
       }
     


       const  handleFetchClassAttendance =()=>{
            
        const fd = new FormData();
        fd.append('jwt', Token)
           let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
           axios.post(url, fd, config)
           .then(result =>{
               const rrs = result.data
              
            let list = getAllSection(result.data)

               //get unique class
        const onlyClass =	list.map(e=>e['classID'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>list[e])
        .map(e=>list[e])

             


               let sqlString = onlyClass.map(data=>"sum(case when s.classID = '"+data.classID+"' and a.attendanceType ='fullday' and a.indicator ='P' and a.attendanceDate ='"+today+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"_present'")

               let sqlStringb = onlyClass.map(data=>"sum(case when s.classID = '"+data.classID+"' and a.attendanceType ='fullday' and a.indicator ='A' and a.attendanceDate ='"+today+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"_absent'")

               let sqlStringc = onlyClass.map(data=>"sum(case when s.classID = '"+data.classID+"' and a.attendanceType ='fullday' and s.gender ='Male' and a.indicator ='P' and a.attendanceDate ='"+today+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"_m_present'")


               let sqlStringd = onlyClass.map(data=>"sum(case when s.classID = '"+data.classID+"' and a.attendanceType ='fullday' and s.gender ='Male' and a.indicator ='A' and a.attendanceDate ='"+today+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"_m_absent'")


               let sqlStringe = onlyClass.map(data=>"sum(case when s.classID = '"+data.classID+"' and a.attendanceType ='fullday' and s.gender ='Female' and a.indicator ='P' and a.attendanceDate ='"+today+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"_f_present'")


               let sqlStringf = onlyClass.map(data=>"sum(case when s.classID = '"+data.classID+"' and a.attendanceType ='fullday' and s.gender ='Female' and a.indicator ='A' and a.attendanceDate ='"+today+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"_f_absent'")



                let sql =   'SELECT '+String(sqlString)+ ', '+String(sqlStringb)+ ', '+String(sqlStringc)+ ', '+String(sqlStringd)+ ', '+String(sqlStringe)+ ', '+String(sqlStringf)+ ' from tbl_students s, tbl_student_attendance a   where  admissionStatus="Studying" and s.code = a.studentCode'

              

                const fd = new FormData();
               fd.append("sql", sql);
               fd.append('jwt', Token)
                let url2 = ServerUrl+'/fetchBySql_controller';
                axios.post(url2, fd, config).then(response=>{ 

                  let ans = response.data

                   //var school = getAllSection(result.data);
                   let responseData = []

                     for (var i = 0; i< onlyClass.length;  i++) {
                       responseData.push(

                           { 
                            classID:onlyClass[i].classID,
                            arms:String(getClassField(onlyClass[i].classID, rrs, 'arms')),
                            className:String(getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)),
                            present:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)+'_present'],
                            absent:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)+'_absent'],
                            m_present:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)+'_m_present'],
                            f_absent:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)+'_f_absent'],

                            f_present:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)+'_f_present'],
                            m_absent:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)+'_m_absent'],

                           });
                           }  

                           setStudentClass(getClassAtt(responseData)) 

                })
                
           })
           .catch((error)=>Alerts('Error!', 'danger', 'Dashboard: '+JSON.stringify(error.message))) 
               
       }

       const getClassAtt =(data)=>{


        return {
          nursery_present:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.present), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.present), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.present), 0),

          nursery_absent:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.absent), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.absent), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.absent), 0),


          nursery_m_present:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.m_present), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.m_present), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.m_present), 0),

          nursery_f_present:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.f_present), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.f_present), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.f_present), 0),


          nursery_m_absent:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.absent), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.m_absent), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.m_absent), 0),

          nursery_f_absent:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.absent), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.f_absent), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.f_absent), 0),




          primary_present:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.present), 0),
          primary_absent:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.absent), 0),



          primary_f_present:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.f_present), 0),
          primary_f_absent:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.f_absent), 0),

          primary_m_present:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.m_present), 0),
          primary_m_absent:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.m_absent), 0),



          secondary_present:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.present), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.present), 0),

         secondary_absent:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.absent), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.absent), 0),


         secondary_m_present:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.m_present), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.m_present), 0),

         secondary_f_absent:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.f_absent), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.f_absent), 0),

         secondary_f_present:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.f_present), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.f_present), 0),

         secondary_m_absent:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.m_absent), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.m_absent), 0),
        }
       

       }



       const  handleFetchClass =()=>{
            
        const fd = new FormData();
        fd.append('jwt', Token)
           let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
           axios.post(url, fd, config)
           .then(result =>{
               const rrs = result.data
              
            let list = getAllSection(result.data)

               //get unique class
        const onlyClass =	list.map(e=>e['classID'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>list[e])
        .map(e=>list[e])

             


               let sqlString = onlyClass.map(data=>"sum(case when classID = '"+data.classID+"'  then 1 else 0 end) AS '"+ getClassName(data.classID, data.classID, rrs)+"'")

                let sql =   'SELECT '+String(sqlString)+ ' from tbl_students  where  admissionStatus="Studying"'

              


                const fd = new FormData();
               fd.append("sql", sql);
               fd.append('jwt', Token)
                let url2 = ServerUrl+'/fetchBySql_controller';
                axios.post(url2, fd, config).then(response=>{ 

                  let ans = response.data

                   //var school = getAllSection(result.data);
                   let responseData = []

                     for (var i = 0; i< onlyClass.length;  i++) {
                       responseData.push(
                           { 
                            classID:onlyClass[i].classID,
                            arms:String(getClassField(onlyClass[i].classID, rrs, 'arms')),
                            className:String(getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)),
                            total:ans[0][getClassName(onlyClass[i].classID, onlyClass[i].classID, rrs)],

                           });
                           } 
                   setSchoolClassList(getClassStat(responseData)) 

                })
                
           })
           .catch((error)=>Alerts('Error!', 'danger', 'Dashboard: '+JSON.stringify(error.message))) 
               
       }


       const getClassStat =(data)=>{


        return {
          nursery:data.filter(item=>item.arms==='Creche').reduce((initial, item)=>initial+Number(item.total), 0)+data.filter(item=>item.arms==='Pre-Nursery').reduce((initial, item)=>initial+Number(item.total), 0)+data.filter(item=>item.arms==='Nursery').reduce((initial, item)=>initial+Number(item.total), 0),
          primary:data.filter(item=>item.arms==='Primary').reduce((initial, item)=>initial+Number(item.total), 0),
          secondary:data.filter(item=>item.arms==='Junior Secondary School').reduce((initial, item)=>initial+Number(item.total), 0)+data.filter(item=>item.arms==='Senior Secondary School').reduce((initial, item)=>initial+Number(item.total), 0),
          all:data.reduce((initial, item)=>initial+Number(item.total), 0)
        }
       

       }


       const fetchStatistics =()=>{
        
  var sql = "select (SELECT count(*) from tbl_student_attendance where attendanceType ='fullday' and indicator ='P' and attendanceDate ='"+today+"' ) as today_present, (SELECT count(*) from tbl_student_attendance where attendanceType ='fullday' and indicator ='A' and attendanceDate  = '"+today+"' ) as today_absent, (SELECT count(s.code) from tbl_student_attendance a, tbl_students s where s.code = a.studentCode and s.gender ='Female' and  a.attendanceType ='fullday' and a.indicator ='A' and a.attendanceDate = '"+today+"' ) as today_f_absent, (SELECT count(s.code) from tbl_student_attendance a, tbl_students s where s.code = a.studentCode and s.gender ='Male' and  a.attendanceType ='fullday' and a.indicator ='A' and a.attendanceDate = '"+today+"' ) as today_m_absent, (SELECT count(s.code) from tbl_student_attendance a, tbl_students s where s.code = a.studentCode and s.gender ='Male' and  a.attendanceType ='fullday' and a.indicator ='P' and a.attendanceDate = '"+today+"' ) as today_m_present, (SELECT count(s.code) from tbl_student_attendance a, tbl_students s where s.code = a.studentCode and s.gender ='Female' and  a.attendanceType ='fullday' and a.indicator ='P' and a.attendanceDate  = '"+today+"' ) as today_f_present  "

          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        
        axios.post(url, fd, config).then(result=>{ 
            
            setStatistics(result.data[0])
        })
    
    }

   useEffect(()=>{
     fetchStaffMonthlyAttendance()
     fetchStudentAttendance()
     fetchStaffAttendance()
     handleFetchClass()
     fetchStatistics()
     handleFetchClassAttendance()
},[]);

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

<div className="row">

<div className="col-xl-3">


<div className="card o-hidden">
<div className="card-block bg-c-green text-white">
  

<h6>Active Students (All) </h6>
<h5 className='text-center'>{schoolClassList.all} </h5>


</div>

<div className="card-footer text-center">
<div className="row">
<div className="col-6 b-r-default">
<h4>{statistics.today_present}</h4>
<p className="text-muted m-b-0">Today Present</p>
<br/>

<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{statistics.today_m_present}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{statistics.today_f_present}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>

</div>
<div className="col-6">
<h4>{statistics.today_absent}</h4>
<p className="text-muted m-b-0">Today Absent</p>
<br/>
<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{statistics.today_m_absent}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{statistics.today_f_absent}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>
</div>
</div>
</div>
</div>
</div>



<div className="col-xl-3">


<div className="card o-hidden">
<div className="card-block bg-c-pink text-white">
  

<h6>Active Students (Nursery) </h6>
<h5 className='text-center'>{schoolClassList.nursery} </h5>


</div>

<div className="card-footer text-center">
<div className="row">
<div className="col-6 b-r-default">
<h4>{studentClass.nursery_present}</h4>
<p className="text-muted m-b-0">Today Present</p>
<br/>

<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{studentClass.nursery_m_present}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{studentClass.nursery_m_present}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>

</div>
<div className="col-6">
<h4>{studentClass.nursery_absent}</h4>
<p className="text-muted m-b-0">Today Absent</p>
<br/>
<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{studentClass.nursery_m_absent}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{studentClass.nursery_f_absent}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>
</div>
</div>
</div>
</div>
</div>



<div className="col-xl-3">


<div className="card o-hidden">
<div className="card-block bg-c-blue text-white">
  

<h6>Active Students (Primary) </h6>
<h5 className='text-center'>{schoolClassList.primary} </h5>


</div>

<div className="card-footer text-center">
<div className="row">
<div className="col-6 b-r-default">
<h4>{studentClass.primary_present}</h4>
<p className="text-muted m-b-0">Today Present</p>
<br/>

<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{studentClass.primary_m_present}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{studentClass.primary_f_present}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>

</div>
<div className="col-6">
<h4>{studentClass.primary_absent}</h4>
<p className="text-muted m-b-0">Today Absent</p>
<br/>
<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{studentClass.primary_m_absent}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{studentClass.primary_f_absent}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


<div className="col-xl-3">


<div className="card o-hidden">
<div className="card-block bg-c-yellow text-white">
  
<h6>Active Students (Secondary) </h6>
<h5 className='text-center'>{schoolClassList.secondary} </h5>

</div>
<div className="card-footer text-center">
<div className="row">
<div className="col-6 b-r-default">
<h4>{studentClass.secondary_present}</h4>
<p className="text-muted m-b-0">Today Present</p>
<br/>

<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{studentClass.secondary_m_present}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{studentClass.secondary_f_present}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>

</div>
<div className="col-6">
<h4>{studentClass.secondary_absent}</h4>
<p className="text-muted m-b-0">Today Absent</p>
<br/>
<div className="row text-center">
<div className="col-6 b-r-default">
<h5 className="m-b-0 f-w-600 ">{studentClass.secondary_m_absent}</h5>

<h6 className="text-muted m-b-10">M</h6>
</div>
<div className="col-6">
<h5 className="m-b-0 f-w-600 ">{studentClass.secondary_f_absent}</h5>

<h6 className="text-muted m-b-10">F</h6>
</div>
</div>
</div>
</div>
</div>



</div>
</div>

</div>

               

    <br/>


    <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <div className="col-sm-6 pull-left ">
             <strong>
     Class Attendnace </strong>
               </div>
             <div className="col-sm-4 pull-right ">
             <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  name="studentClass" value={search.studentClass}  /> 
			</div>
							</div>
              <Bar
          data={data}
          options={options}
          
        /> 

             </div>
          </div>
        </div>

        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Student Attendance ({thisMonth}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataStudentAttendance}
          options={optionsStudentAttendance}
          
        /> 

             </div>
          </div>
        </div></div> 


        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> STAFF MONTHLY ATTENDANCE</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataStark}
          options={optionsStark}
        />  
             </div>
          </div>
        </div></div>


        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Staff Attendance ({thisMonth}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataStaffAttendance}
          options={optionsStaffAttendance}
          
        /> 

             </div>
          </div>
        </div></div> 





   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Chart) 