import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
const Navbar = (props)=>{
    
    const menu  = useSelector(state => state.rootReducer.sidebar);
    const userRole  = useSelector(state => state.rootReducer.roles);
   

    let location =  useLocation()
    const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });


const handleToggleDisplaySubmenu=(main, sub)=>{
   // handleToggleDisplay(main)
   if(sub!=='0'){
   
    setIsDisplay({[main]: true});
    setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
}else{
    setIsDisplay({[main]: !isDisplay[main]})
}
}

const [isDisplay, setIsDisplay] = useState({ "0": true });

let activeHasmenuOpen ='pcoded-hasmenu pcoded-trigger active'




    return (  
        <nav className="pcoded-navbar no-print"    >
        <div className="pcoded-inner-navbar main-menu " >
            <div className="pcoded-navigatio-lavel" menu-title-theme="theme5" ></div>
            
             <ul className="pcoded-item pcoded-left-item no-print">
               {menu.length!==0?menu.map((data, index)=>userRole.includes(data.ID)&&data.isActive==='Yes'?
        <li  key={index} dropdown-icon={data.submenu.length!==0?"style2":''} subitem-icon={data.submenu.length!==0?"style2":''} 
        
        className={data.submenu.length ===0 && location.pathname===data.menuUrl?'active':
           
            isDisplay[data.ID] && props.menu===data.menuName && data.submenu.length!==0?activeHasmenuOpen:
            props.menu===data.menuName && data.submenu.length!==0?'pcoded-hasmenu active':
            isDisplay[data.ID] && data.submenu.length !==0 ?'pcoded-hasmenu pcoded-trigger':
        
        data.submenu.length !==0?'pcoded-hasmenu':  ''}
        
        >
                  
{data.submenu.length!==0? <a href='#!' title={data.menuName} onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}>
<span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                        <span className="pcoded-mtext ">{data.menuName}</span>

</a>:

<Link to={data.menuUrl} > 
   <span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                        <span className="pcoded-mtext ">{data.menuName}</span>
                 
                    </Link>}
                    {data.submenu.length!==0?
                    <ul className="pcoded-submenu">
                  {data.submenu.map((smenu, sid)=>userRole.includes(smenu.ID)&&smenu.isActive==='Yes'?
          <li key ={sid} 

    className={smenu.subsubmenu.length ===0 && location.pathname===smenu.subUrl?'active':
    
        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName?activeHasmenuOpen: 
        
        isDisplay[data.ID] && isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName ?'pcoded-hasmenu pcoded-trigger active': 
        

        isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0?'pcoded-hasmenu pcoded-trigger':
         smenu.subsubmenu.length !==0?'pcoded-hasmenu':
                        ''}  
                        dropdown-icon={smenu.subsubmenu.length!==0?"style3":''} subitem-icon={smenu.subsubmenu.length!==0?"style3":''}
                        
                        >
                

{smenu.subsubmenu.length!==0?    <a href='#!'
                        onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>

<span className="pcoded-micon "><i className="feather icon-menu"></i></span>
                        <span className="pcoded-mtext " >{smenu.subName}</span>

                        </a>  :    
<Link to={smenu.subUrl} >   
                        <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                        <span className="pcoded-mtext" >{smenu.subName}</span>
               
                    </Link>}
                    {smenu.subsubmenu.length!==0?
                    <ul className="pcoded-submenu">

                        {smenu.subsubmenu.map((subsub, k)=>userRole.includes(subsub.ID)&&subsub.isActive==='Yes'?
                                <li   className={location.pathname===subsub.subsubUrl?'active':'' } key={k}  >
                                  
<Link to={subsub.subsubUrl} > 
                                        <span className="pcoded-mtext">{subsub.subsubName}</span>
                               
                                    </Link>
                                </li>:'')}

                            </ul>:''}
                        </li>:'')}
                       
                    </ul>:''}
                </li>:''):<div className='loadit'></div>}

                <li className="">
                    
                <a href="/logout">
                       
                <span className="pcoded-micon"><i className="feather icon-power"></i></span>
                                    <span className="pcoded-mtext">Log Out</span>
                    </a>
                            </li>

            </ul> 
 
           
            <div className="pcoded-navigatio-lavel">Support</div>
            <ul className="pcoded-item pcoded-left-item">
           
                <li className="">
                    <a href="mailto:opeyemiademon@gmail.com">
                        <span className="pcoded-micon"><i className="fa fa-bug"></i></span>
                        <span className="pcoded-mtext">Submit Issue</span>
                    </a>
                </li>
               
              
                <li className="">
                    <a href="https://api.whatsapp.com/send?phone=2348032950881">
                        <span className="pcoded-micon"><i className="feather icon-help-circle"></i></span>
                        <span className="pcoded-mtext">Suggest a feature</span>
                    </a>
                </li>
            </ul>
        </div>
        
    </nav>
    
    
                );
}
export default React.memo(Navbar)