
const records = {
    staff:[],
    students:[],
    hostel:[],
    schoolClass:[],
    schoolSession:[],
    schoolInfo:[],
    totalStudent:0,
    countries:[],
    sidebar:[],
    menuLink:[],
    roles:[],
    states:[],
    group:[],
    user:[]
}

export const rootReducer =(state = records, action)=>{
    switch(action.type){
        case 'SET_RECORDS':
            return {...state, [action.name]:action.data}
        case 'FETCH_RECORDS':
            return state;
            default:
                return state
    }

}
