import React, {useState, useEffect,  useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel} from '../component/globalFunction'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useSelector } from 'react-redux'

 const Transfer =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    

    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
	const [totalFees, setTotalFees] =  useState(0);

    const menuLink  = useSelector(state => state.rootReducer.menuLink);
    const [feecharge, setFeecharge] = useState([]);
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [classFee, setClassFee] =  useState([]);
const [isDiplaying, setIsDiplaying] = useState(false);
const [actualFee, setActualFee] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feeType, setFeeType] =  useState([]);
    const [students, setStudents] = useState([]);  
const [studentTransfer, setStudentTransfer]= useState({
        feecharge:'',
        remark:'',
        feeStructure:'',
        fromClass:[],
         toClass:[],
         student:[],
})


const handleChangeFee =(index, e)=>{
    const fees =JSON.parse(JSON.stringify(classFee))   
   const value =e.target.value;
  fees[index].price = value;

  var totalFee = 0;
 fees.map(item=>totalFee+=Number(item.price))
   setClassFee(fees)
   setTotalFees(totalFee)
    
}
const getActualFee = (ID) =>{        
    const result = actualFee && actualFee.filter(list=>list.ID===ID);
    const answer =result?result.map(lst=>lst.price):[]
    return   answer

    }

    const fetchFeecharge =()=>{
        const fd = new FormData();
    fd.append("jwt", Token);
        let url = ServerUrl+'/fetch_controller/tbl_fees_charge'
        axios.post(url, fd, config).then(result=>setFeecharge(result.data))
    }
    
    const  handleFetchClassFee =(section)=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+section
            setNotice({...notice, 
                isLoading: true
            });
            axios.post(url, fd, config).then(result => {   
                if(result.data.length!==0){
                    var totalFee = 0;
                    result.data.map(item=>totalFee+=Number(item.price))
                    setClassFee(result.data);
                setActualFee(result.data);
                setTotalFees(totalFee)
                }  else{
                    setClassFee('');
                setActualFee('');
                setTotalFees(0)
                }
                
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false
                });
            })
        }


        const handleSelect = (option, action)=>{
            setStudentTransfer({...studentTransfer, [action.name]: option});
            setErrors({...errors, [action.name]:''})
             }

             const  handleStudents =(section)=>{  
                setNotice({...notice,  isLoading: true });
                

                var sql ="Select code, admissionNumber, father_mobile,  studentName, fatherName, registerNumber from tbl_students where sectionID ='"+section+"' and admissionStatus = 'Studying' " ;
                const fd = new FormData();
                fd.append("sql", sql);
                fd.append("jwt", Token);
                let url = ServerUrl+'/fetchBySql_controller';
                axios.post(url, fd, config).then(result=>{
                    
                    if(result.data.length!==0){
                        setStudents(result.data)
                    }else{
                        setStudents('')
                    }
                    
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice,  isLoading: false });
                })
                
                 }

             const handleSelectFrom = (option, action)=>{
                setStudentTransfer({...studentTransfer, [action.name]: option});
                 handleStudents(option.value);
                 setErrors({...errors, [action.name]:''})
             }


             const handleSelectTo = (option, action)=>{
                setStudentTransfer({...studentTransfer, [action.name]: option
                });
                setErrors({...errors, [action.name]:''})
                setIsDiplaying(true);
                //handleFetchClassFee(option.value);
               // structure.current.scrollIntoView({ behavior: "smooth" });
                 }


const handleChange =(event)=>{
        
    const {name, value} = event.target;
        setStudentTransfer({...studentTransfer, [name] : value });
        setErrors({...errors, [name]:''})
        
       }

  

 const handleSubmit = event =>{
    event.preventDefault();
    setErrors(ValidateInput(studentTransfer)); 
    setIsSubmitting(true);
} 



const ValidateInput =(values)=>{
	let errors = {};   
        
    if(values.fromClass.value === values.toClass.value ){
        errors.toClass ='Same Class Section selected, kindly change class section to be transfer to ';
    }

    if(values.toClass.length===0){
        errors.toClass ='Please select to Class';
	}
    if(values.fromClass.length===0){
        errors.fromClass ='Please select current Class ';
        window.scrollTo(0,0);
    }
    if(students.length===0){
        errors.student ='There are no student in the selected class ';
        window.scrollTo(0,0);
    } 

    if(values.student.length===0){
        errors.student ='Please select student to transfer';
        window.scrollTo(0,0);
    }

    /* if(values.feecharge.length===0){
        errors.feecharge ='Please select fees charge';
        window.scrollTo(0,0);
    } */

   /*  if(classFee.length===0){
        errors.feeStructure ='There is no school fees define ';
    }  */
    
    
    return errors;
	}
	
	

function submit(){  
    setNotice({...notice, isLoading: true}) 
      const fd = new FormData(); 
    fd.append("jwt", Token);
    fd.append('classId', studentTransfer.toClass.classID);
        fd.append('sectionId', studentTransfer.toClass.value);
        fd.append('code', studentTransfer.student.value);
        fd.append('feeStructure', JSON.stringify(classFee, null, 2));
        fd.append('feecharge', 'studentTransfer.feecharge.value');
        fd.append('remark', studentTransfer.remark);
        fd.append('totalFee', totalFees);
        fd.append('admissionNumber', studentTransfer.student.label);

      let url = ServerUrl+'/update_controller/tbl_transfer_student'
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
		} else{
			setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	}  
	})
	.catch((error)=>{
        setErrors({...errors, errorMessage: JSON.stringify(error.message)})
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
      setIsDiplaying(false);
      setIsSubmitting(false); 
      setStudentTransfer({
          toClass:[],
          feecharge:[],
          fromClass:[],
          remark:'',
          student:[],
    });
})
}

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])


       
   
            const  handleFetchFeeType =()=>{
                const fd = new FormData();
                var sql ="Select * from tbl_fee_type where status ='true'"; 
                fd.append("sql", sql);
                fd.append("jwt", Token);
                let url = ServerUrl+'/fetchBySql_controller';
                axios.post(url, fd, config).then(res =>setFeeType(res.data))
                .catch((error)=>{
                  //  Alerts('Error!', 'danger', error.message)
                })
                            }


    useEffect(()=>{   

        if(menuLink.length!==0){
            setIsAuthenticated(menuLink.includes(location.pathname))
        }
        
        },[menuLink]);

        useEffect(()=>{
            fetchFeecharge()
            handleFetchFeeType();
		},[]);
        
        return ( <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transfer Student">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Transfer Student</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
								

                                
{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit"></i> Transfer From Class To Class</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather minimize-card icon-minus"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
		<fieldset>
        <div className="row">
<div className="col-sm-12">

				
	<div className="card z-depth-0">
										<section className="card-block">
                                           
										
	{/* <div className="form-group">
	
	<label >Fee Charge <span style={{color:'red'}}>*</span> </label>
	<Select  options={feecharge&&feecharge.map((list, idx)=> {
								   return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
								 })
							} 
							getOptionLabel={option =>`${option.session}  ${option.label}`}       
	onChange={handleSelect} name="feecharge" value={studentTransfer.feecharge} className={errors.feecharge ? 'form-control form-control-danger' : ''}  /> 
	<span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>
				</div> */}

					<div className="form-group">
						<label>Current Class</label>
						<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectFrom} className={errors.fromClass ? 'form-control form-control-danger' : ''} name="fromClass" value={studentTransfer.fromClass}  /> <span style={{color:'red'}}>{errors.fromClass && errors.fromClass}</span>
</div>

	<div className="form-group">
						<label>Student</label>
						<Select  options={
                            students&&students.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName, co:list.fatherName, phone:list.father_mobile  }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.co}  ${option.phone}`}
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={studentTransfer.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
											
                                            
                                            
                 <div className="form-group">
						<label>To Class</label>
						<Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectTo} className={errors.toClass ? 'form-control form-control-danger' : ''} name="toClass" value={studentTransfer.toClass}  /> <span style={{color:'red'}}>{errors.toClass && errors.toClass}</span>
												
										</div>
		</section></div></div>
                                        
                                        
                                        </div>
                   {/*                      {isDiplaying ? 		
							<div className='row' >

					<section className="col-md-12"><br/>
<label><b>Class Fee Structure</b></label>
	 
<div className="table-responsive">
      <table className="table ">
        <tbody >

{classFee.length!==0 ? classFee.map((fee, idx)=> 
<tr key={idx}>
				<td>{fee.fees}<br/><span style={{fontSize: '10px',color: '#fa0000'}}>Actual Fee {Currency+FormatNumber(getActualFee(fee.ID))}</span></td>

				<td><input type="number" min="0" className="form-control" onChange={(e)=>handleChangeFee(idx, e)}  name="price[]" value={fee.price} /></td>
			</tr>
				):<tr><td colSpan="3">
					<div className="alert alert-danger background-danger">There are no fee structure define for the selected class, please contact the admin</div>
					</td></tr>
			
			}
				</tbody>
				{classFee.length!==0 ?	<tfoot>
					<tr>
						<td colSpan='' className='text-right'><b>Total Fees:</b></td>
						<td><b>{Currency+FormatNumber(totalFees)}</b></td>
					</tr>
				</tfoot>:[]}
		</table>
		</div>
					</section>
					<section className="col-md-12">
					{classFee.length!==0 ? <table className="table ">
				<tbody >
			
			<tr>
			<td colSpan="3"><label>Remark</label>
				<textarea value={studentTransfer.remark} rows='2' name='remark' className='form-control' onChange={handleChange}> {studentTransfer.remark}</textarea>
					</td>
			

			</tr>
			</tbody></table>:[]}
			</section></div>	:""} */}
										
            <div ref={structure} />		</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-refresh"></i> Confirm Transfer</button>
	
								</footer>				
							</div>
							</div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Transfer) 