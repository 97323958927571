import Cookies from 'js-cookie'
import { decrypt } from './authentication'
var CryptoJS = require('crypto-js')


export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



//school name and LocalUrl should be entered manually
export const schoolName = 'PAGE INTERNATIONAL SCHOOL'
export const Currency = '₦'


export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const studentUrl= "https://learners."+mainUrl
export  const teacherUrl= "https://staff."+mainUrl
export  const ParentUrl=  "https://parent."+mainUrl
export  const applicantUrl=  "https://forms."+mainUrl
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const ImagesUrl = "https://app."+mainUrl+'/images'
export const domain = "."+mainUrl


export const APP_KEY =process.env.REACT_APP_APP_KEY;
export  const LocalUrl = 'localhost'
export  const KAYUS_ULR = 'https://kayus.org'
export const DatabaseName= process.env.REACT_APP_DATABASE_NAME
export const smsAccountUrl = process.env.REACT_APP_SMS_ACCOUNT_URL;


export const SMART_SMS_TOKEN =process.env.REACT_APP_SMARTWEB_SMS_TOKEN;

export  const PSKLIVE = process.env.REACT_APP_PSKLIVE; 
export  const PSKTESTPublic = process.env.REACT_APP_PSKTEST;





export  const frontUrl = 'https://app.pageschoolsportal.org';
export  const siteLink = mainUrl
//export const LocalUrl ='app.pageschool.com'





export const staffCode = Cookies.get('kayus_code')?CryptoJS.AES.decrypt(Cookies.get('kayus_code'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const Token = Cookies.get('kayus_token')?CryptoJS.AES.decrypt(Cookies.get('kayus_token'), APP_KEY).toString(CryptoJS.enc.Utf8):undefined

export const menuLink = localStorage.getItem('kayus_link')? JSON.parse(decrypt(localStorage.getItem('kayus_link'))):[]


export const allowEdit = Cookies.get('kayus_ed')?CryptoJS.AES.decrypt(Cookies.get('kayus_ed'), APP_KEY).toString(CryptoJS.enc.Utf8):undefined
export const allowDelete = Cookies.get('kayus_dt')?CryptoJS.AES.decrypt(Cookies.get('kayus_dt'), APP_KEY).toString(CryptoJS.enc.Utf8):undefined



export const config = {
    headers: { 
        
        'content-type': 'multipart/form-data',

}} 

export const NoConfig = {
    headers: { 
        
        'content-type': 'application/json'

}

}
export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };






