import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config, Currency, FormatNumber, staffCode} from '../component/include'


const BulkRegister =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    
    const [subject, setSubject]= useState([])    
    const [purpose, setPurpose]= useState([])
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [bookList, setBookList]= useState([])

    const Initials = 
    { 
        purpose:'',
        subject:'',
        bookName:'',
        authorName:'',
        publisher:'',
        price:0,
        isbn:'',
        accessNo:'',
        classMark:'',
        publishYear:''
    }


    const fetchSubject =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    const fetchPurpose =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/purpose/ID/1'
        axios.post(url, fd, config)
        .then(result =>setPurpose(result.data))
    }



       const  GenerateRow =()=>{  
                var value = [];
                for (var i = 1; i <= 10; i++) {
                  value.push({...Initials,  code:Math.random().toString(36).substr(2, 9)});
                }      
                setBookList(value)

            } 


       const createNewRow =()=>{
        setBookList(bookList.concat({...Initials, code:Math.random().toString(36).substr(2, 9)}))
        setErrors({...errors, bookList:'', errorMessage:''})

    }

    const removeRow=(index)=>{
        setBookList(bookList.filter((_, i) => i !== index)); 
       }


      
    const handleChangeBook = (e, index)=>{
        const std =JSON.parse(JSON.stringify(bookList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
           setBookList(std);
         //  setErrors({...errors,  errorMessage:''})
    }

    

    const handleSubmit = event =>{
		event.preventDefault();
		setErrors(validateInput());
		setIsSubmitting(true);
        window.scrollTo(0,0);
	} 
	
const validateInput =()=>{
	let errors = {};   
    if(bookList.length===0){
        errors.bookList ='Please register at least one book';
    }
	
    return errors;
	}
	




useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
       submit()
    }
    },[errors])


    const  submit=()=>{  
                
        var options = bookList;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if(options[i].bookName!=='' ){
                 value.push({
            
            code:options[i].code, 
            subject:options[i].subject,
            bookName:options[i].bookName,
            price:options[i].price,
            purpose:options[i].purpose,
            authorName:options[i].authorName,
            publisher:options[i].publisher,

            isbn:options[i].isbn,
            accessNo:options[i].accessNo,
            classMark:options[i].classMark,
            publishYear:options[i].publishYear,
        })
            }
       
    }

    if(value.length!==0){

        setNotice({...notice, isLoading: true})   

        
const fd = new FormData();
fd.append('jwt', Token)    
fd.append('bookList',  JSON.stringify(value, null, 2))

     let url = ServerUrl+'/save_controller/tbl_bulk_books' 
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Success', 'success', response.data.message)
                            }else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                    }   
            })
            .catch((error)=>{
             setErrors({...errors, errorMessage: JSON.stringify(error.message)})
            }).finally(()=>{
                setIsSubmitting(false)
                GenerateRow()
        setNotice({...notice,  isLoading: false}) 
    }) 

}else{
    setErrors({...errors, errorMessage: 'Please fill at least one row complete before you can submit'})
    window.scrollTo(0,0);
}
     }    



useEffect(()=>{
    GenerateRow()
    fetchPurpose();
    fetchSubject();
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Books Bulk Registration">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Bulk Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

<div  className="row  ">   
<div className="well">You can use this page to add bulk books. Please be careful that you select the right Subject and Purpose. You can enter as many rows as you like by clicking on Add more.</div>
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Register Library Books</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            

{bookList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr style={{background:'#404E67', color:'#fff'}}>
                <th>SNo</th>
                    
                    <th>Title of Materials</th>
                    <th>Author's Name</th>  
                    <th>Publisher</th> 
                    <th>Price</th> 
                    <th>Access No</th>
                    <th>ISBN</th>
                    <th>Class. Mark</th>
                    <th>Pub. Year</th>
                    <th>Subject</th>
                    <th>Purpose</th>
    <th ><a onClick={createNewRow} href="#!" style={{color:'#fff'}}><i className="fa fa-plus"></i> Add More</a></th>
                </tr>
            </thead>
            <tbody>


                {bookList&&bookList.map((pl, id)=>
            <tr key={id}>
                
                    <td style={{backgroundColor:'#d2d6de'}}>{id+1}</td>
                   


                      <td>
                      <input type="text" className="form-control" name='bookName' autoComplete="false" value={pl.bookName}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>

                      <td>
                      <input type="text" className="form-control" name='authorName' autoComplete="false" value={pl.authorName}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>

                      <td>
                      <input type="text" className="form-control" name='publisher' autoComplete="false" value={pl.publisher}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>
                   
                      <td>
                      <input type="number" pattern="\d*" className="form-control" name='price' autoComplete="false" value={pl.price}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>


                     
                   
                      <td>
                      <input type="text"  className="form-control" name='accessNo' autoComplete="false" value={pl.accessNo}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>
                      
                      <td>
                      <input type="text" className="form-control" name='isbn' autoComplete="false" value={pl.isbn}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>
                      <td>
                      <input type="text"  className="form-control" name='classMark' autoComplete="false" value={pl.classMark}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>

                      <td>
                      <input type="text"  className="form-control" name='publishYear' autoComplete="false" value={pl.publishYear}  onChange={(e)=>handleChangeBook(e, id)} />
                      
                      </td>

                      <td > 
                        <select onChange={(e)=>handleChangeBook(e, id)}  name="subject"  value={pl.subject} className="form-control">

                    <option value="">Select</option>
                    { subject&&subject.map((list, idx)=> {
                            return  <option key={idx} value={list.code} >{list.subjectName}</option> })}
                        </select>
                     </td>

                    <td>
                    <select onChange={(e)=>handleChangeBook(e, id)}  name="purpose"  value={pl.purpose} className="form-control">

                    <option value="">Select</option>
                    { purpose&&purpose.map((list, idx)=> {
                            return  <option key={idx} value={list.code} >{list.purposeName}</option> })}
                        </select>
                        
                        </td>

                    <td > <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                </tr>)}
               
            </tbody> 
            <tfoot>
                <tr >
                    <td colSpan="8">

                    
                <div className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success btn-block"><i className="fa fa-save"></i> Submit </button>
								</div>
                                </td>
                </tr>
            </tfoot>
        </table>

        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(BulkRegister) 