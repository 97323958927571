import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config} from '../component/include'
import { longDate } from '../component/globalFunction'

const MessageReport =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
}); 

     
    const [messageReport, setMessageReport]= useState([])    
    

const handleAction =  useCallback((cell, row)=>{
        
    return <React.Fragment>    
    <button className="btn btn-inverse"    title="Resend"><i className="fa fa-arrow-left"></i>Resend</button>
    </React.Fragment> 
},[])

				
const tableHeader = [
{dataField: 'ID', text: 'Action', formatter: handleAction, editable: false},
        {dataField: 'message', text: 'Message Content', editable: false},
        {dataField: 'status', text: 'Status', editable: false},       
        {dataField: 'dateSent', text: 'Date Send', formatter:(cell)=>longDate(cell), editable: false},
    {dataField: 'smsPage', text: 'Page'}, 
    {dataField: 'totalMobile', text: 'Total Mobile'}
 ];
 
useEffect(()=>{
    fetchReport()
},[]);



const fetchReport=()=>{


	setNotice({...notice,  isLoading: true})     
		  
		const fd = new FormData();
	fd.append('jwt', Token);
		
	   
		   let url = ServerUrl+'/plugins/smsreport.php'
		  axios.post(url, fd, config)
		  .then(response =>{
            setMessageReport(response.data.data)
		  })
		  .catch((error)=>{
			setErrors({...errors, errorMessage: error.message})
		  }).finally(()=>{
			  setNotice({...notice,   isLoading: false })
		  }) 	
}

        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Communication">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Message Report</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Message Report</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12">
            <div className="table-responsive">
                {messageReport}

            </div></div></div>


    
    </div>
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(MessageReport) 